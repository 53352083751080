const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://thainguyen-api.cgis.asia/",
    DomainAdminSide:"http://localhost:3000",
    DomainUserSide:"http://localhost:3001",
    WmsBaseLink:"https://geo.cgis.asia/geoserver/thainguyen/wms",
  },
  production: {
    api: "https://thainguyen-api.cgis.asia/",
    DomainAdminSide:"https://admin.thainguyen.cgis.asia",
    DomainUserSide:"https://thainguyen.cgis.asia",
    WmsBaseLink:"https://geo.cgis.asia/geoserver/thainguyen/wms",
  },
};

module.exports = apiEnvironment[env];
