/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as serviceLinkAction from "../../../redux/store/service-link/service-link.store";
import * as config from "../../../common/config";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddServiceLinkManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    ShowNotificationError,
    GetListServiceLink,
    rowsPerPage,
    showLoading,
  } = props;

  const [linkGroupLookup, setLinkGroupLookup] = useState();
  const [avatar, setAvatar] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    showLoading(true);
    serviceLinkAction
      .GetLookupLinkGroup()
      .then((res) => {
        setLinkGroupLookup(res && res.content ? res.content : []);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    showLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("file", avatar);
    formData.append("groupId", data.groupId);
    formData.append("link", data.link);
    formData.append("order", data.order);
    formData.append("active", isActive);
    serviceLinkAction
      .CreateServiceLink(formData)
      .then((result) => {
        if (result && result.content && result.content.status === true) {
          showLoading(false);
          GetListServiceLink(1, rowsPerPage);
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.AddProvice,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > config.Configs.DefaultCapacityFile) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 5MB!");
    } else if (
      rejectFiles.type === "image/*" &&
      (rejectFiles.type === "image/jpeg" ||
        rejectFiles.type === "image/png" ||
        rejectFiles.type === "image/jpg" ||
        rejectFiles.type === "image/gif")
    ) {
      ShowNotificationError("Ảnh nhập vào không đúng định dạng");
    } else {
      ShowNotificationError("File nhập vào phải là ảnh");
    }
  };

  const handleChange = (files) => {
    if (files) {
      setAvatar(files[0]);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm danh sách liên kết</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Tên danh sách liên kết<span className="required"></span>
                  </label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Sắp xếp<span className="required"></span>
                  </label>
                  <TextField
                    type="text"
                    name="order"
                    className="w-100"
                    inputRef={register({
                      required: true,
                    })}
                    onChange={(e) =>
                      setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                    }
                    error={errors.order && errors.order.type === "required"}
                  />
                  {errors.order && errors.order.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Danh sách nhóm<span className="required"></span>
                  </label>
                  <Select
                    fullWidth
                    onChange={(event) =>
                      setValue("groupId", event.target.value)
                    }
                    error={errors.groupId && errors.groupId.type === "required"}
                  >
                    {linkGroupLookup &&
                      linkGroupLookup.length > 0 &&
                      linkGroupLookup.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <TextField
                    type="text"
                    name="groupId"
                    className="w-100"
                    inputRef={register({ required: true })}
                    hidden
                  />
                  {errors.groupId && errors.groupId.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Link</label>
                  <TextField
                    type="text"
                    name="link"
                    className="w-100"
                    inputRef={register}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="text-dark">
                Ảnh (Ảnh nhập vào nhỏ hơn 5MB)
              </label>
              <DropzoneArea
                acceptedFiles={[
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/gif",
                ]}
                showAlerts={false}
                filesLimit={1}
                getDropRejectMessage={(rejectedFile) =>
                  customMessages(rejectedFile)
                }
                getFileLimitExceedMessage={(filesLimit) =>
                  ShowNotification(
                    `Nhiều nhất ${filesLimit} ảnh`,
                    NotificationMessageType.Error
                  )
                }
                dropzoneText={"Chọn ảnh"}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Danh sách nhóm<span className="required"></span>
                  </label>
                  <Select
                    fullWidth
                    defaultValue={isActive}
                    onChange={(event) => setIsActive(event.target.value)}
                  >
                    <MenuItem value={true}>{"Hoạt động"}</MenuItem>
                    <MenuItem value={false}>{"Không hoạt động"}</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
