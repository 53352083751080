import { combineReducers } from "redux";

import AppReducer from "../core/app.store";
import OpenLayerReducer from "./store/openlayer/openlayer.store";
import InitMapReducer from './store/init-map/init-map.store';
import LoadingReducer from './store/loading/loading.store';
import InitDocumentReducer from './store/document/document-management.store';

export default combineReducers({
  app: AppReducer,
  openLayer: OpenLayerReducer,
  initMap: InitMapReducer,
  loadingState: LoadingReducer,
  InitDocument: InitDocumentReducer,
})
