import React, { useEffect, useState } from 'react';
import './init-map-data.scss';
import OpenlayerMapView from '../../components/open-layer/open-layer';
import LeftControlView from './left-control/left-control';
import RenderSettingGeneralPopup from './setting-general-popup/setting-general-popup.view.jsx';
import * as InitMapStore from '../../redux/store/init-map/init-map.store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function InitMapDataView(props) {
    const [extend, setExtend] = useState([]);
    const [zoom, setZoom] = useState();
    useEffect(() => {
        if (props.planningId) {
            if (props.mapId) props.GetMapDetailById(props.mapId, props.planningId); else props.UpdateDefaultMapData(props.planningId)
        } else props.GetMapDetailById(21, 1);
        props.GetAllBaseMapDefault();
        props.GetLayerCategoryParent();
    }, []);

    // useEffect(() => {
    //     if (props.planningId) {
    //         if (props.mapId) props.UpdateDefaultMapData(props.planningId, props.mapId); else props.UpdateDefaultMapData(props.planningId)
    //     } else props.UpdateDefaultMapData(21, 1);
    //     props.GetAllBaseMapDefault();
    // }, []);
    
    return (
        
        <div className="init-map-data-container h-100 container-fluid p-0" style={{ overflowX: "hidden" }}>

            <div className="row h-100 p-0 m-0">
                <div className="col-3 h-100 p-0">
                    <LeftControlView
                        planningId={props.planningId}
                    />
                </div>
                <div className="col-9 h-100 p-0">
                    {
                        props.initMap.hasLoadingData && <OpenlayerMapView setExtend={setExtend} setZoom={setZoom} />
                    }
                </div>
                {
                    props.initMap.isOpenSettingGeneralModal && <RenderSettingGeneralPopup extend={extend} zoom={zoom} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    initMap: state.initMap
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetMapDetailById: InitMapStore.GetMapDetailById,
    GetAllBaseMapDefault: InitMapStore.GetAllBaseMap,
    UpdateDefaultMapData: InitMapStore.UpdateDefaultMapData,
    GetLayerCategoryParent: InitMapStore.GetLayerCategoryParent,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(InitMapDataView)