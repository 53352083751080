import React, { useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './modal-setting.scss';
import SelectDataSourceLayer from '../select-data-source/select-data-source';
import * as InitmapConfig from '../../../config/config';
import SettingLayerTypeView from '../setting-layer/setting-layer';
import NotificationService from '../../../../../common/notification-service';
import SettingInfomationViewLayer from '../layer-infomation-view/layer-infomation-view';
import SettingLayerFilter from '../layer-filter-setting/layer-filter-setting';
import FinishScreen from '../finishScreen/finishScreen';
import { Button, Step, StepLabel, Stepper } from '@material-ui/core';

import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";
import * as viVN from "../../../../../language/vi-VN.json";
import * as appActions from "../../../../../core/app.store";

import * as importShapeFileAction from '../../../../../redux/store/import-shape-tiff-file/import-shape-file.store';
import * as importTiffFileAction from '../../../../../redux/store/import-shape-tiff-file/import-tiff-file.store';

function ModalLayerSetting(props) {
    const { showLoading } = props;
    const [dataSource, setDataSource] = useState(InitmapConfig.CreateDataSourceObject());
    const [layerSetting, setLayerSetting] = useState(InitmapConfig.CreateLayerSettingObject());
    const [layerViewSetting, setLayerViewSetting] = useState(null);
    const [layerFilterSetting, setLayerFilterSetting] = useState(null);

    const [activeStepWms, setActiveStepWms] = useState(0);
    const [activeStepShapeFile, setActiveStepShapeFile] = useState(0);
    const [activeStepTifFile, setActiveStepTifFile] = useState(0);

    const [skipped, setSkipped] = useState(new Set());

    const [isImportShapeFile, setIsImportShapeFile] = useState(true);
    const [isCompleteImportShapeFile, setIsCompleteImportShapeFile] = useState(false);

    const [isImportTifFile, setIsImportTifFile] = useState(false);
    // get value shape file
    const [valueShapeFileStep_1, setValueShapeFileStep_1] = useState({ TableName: "", file: "" });
    const [valueShapeFileStep_2, setValueShapeFileStep_2] = useState({ RootFolderName: "", ShpFileName: "", TableName: "" });
    const [valueShapeFileStep_3, setValueShapeFileStep_3] = useState({ RootFolderName: "", TableName: "", PlanningId: -1 });
    const [valueShapeFileStep_4, setValueShapeFileStep_4] = useState({ RootFolderName: "", TableName: "", StyleName: "" });

    //get value tiff file
    const [valueTiffFile_2, setValueTiffFile2] = useState(null);

    //line step
    const stepWms = ['Thiết lập nguồn dữ liệu', 'Thiết lập thông số', 'Tùy chọn thông tin hiển thị', 'Tùy chọn lọc thông tin'];
    const stepShp = ['Nhập và giải nén tệp', 'Nhập shape file vào postgis', 'Tạo Sld và nhập vào postgis', 'Công bố'];
    const stepTif = ['Nhập workspace', 'Nhập tif'];

    //content step
    function getStepContentWms(step) {
        switch (step) {
            case 0:
                return dataSource
                    ? <SelectDataSourceLayer
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                        isImportShapeFile={isImportShapeFile}
                        setIsImportShapeFile={setIsImportShapeFile}
                        isImportTifFile={isImportTifFile}
                        setIsImportTifFile={setIsImportTifFile}
                        setValueShapeFileStep_1={setValueShapeFileStep_1}
                        valueShapeFileStep_1={valueShapeFileStep_1}
                        isCompleteImportShapeFile={isCompleteImportShapeFile}
                    />
                    : null;
            case 1:
                return <SettingLayerTypeView
                    layerSetting={layerSetting}
                    setLayerSetting={setLayerSetting}
                    isImportShapeFile={isImportShapeFile}
                    isImportTifFile={isImportTifFile}
                    valueShapeFileStep_2={valueShapeFileStep_2}
                    setValueShapeFileStep_2={setValueShapeFileStep_2}
                    setValueTiffFile2={setValueTiffFile2}
                />;
            case 2:
                return dataSource
                    ? <SettingInfomationViewLayer
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                        layerViewSetting={layerViewSetting || new InitmapConfig.CreateLayerDisplayInfomationSettingObjectDefault(dataSource)}
                        setLayerViewSetting={setLayerViewSetting}
                        isImportShapeFile={isImportShapeFile}
                        valueShapeFileStep_3={valueShapeFileStep_3}
                        setValueShapeFileStep_3={setValueShapeFileStep_3}
                    />
                    : null;
            case 3:
                return dataSource
                    ? <SettingLayerFilter
                        layerFilterSetting={layerFilterSetting || new InitmapConfig.CreateLayerFilterObjectDefault(dataSource)}
                        setLayerFilterSetting={setLayerFilterSetting}
                        dataSource={dataSource}
                        isImportShapeFile={isImportShapeFile}
                        valueShapeFileStep_4={valueShapeFileStep_4}
                        setValueShapeFileStep_4={setValueShapeFileStep_4}
                    />
                    : null;
            default:
                return '';
        }
    }

    const isThisStepCompleted = (step) => {
        switch (step) {
            case 0: return (dataSource.wms) ? true : false;
            default: return true;
        }
    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    //--- Wms
    const handleNextWms = (step) => {
        switch (step) {
            case 1: {
                if (!layerSetting.layerName) {
                    NotificationService.error("Trường tên layer không được để trống")
                    return;
                }
                setActiveStepWms((prevActiveStep) => prevActiveStep + 1);
                break;
            }
            default: {

                let newSkipped = skipped;
                if (isStepSkipped(activeStepWms)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStepWms);
                }
                setActiveStepWms((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            }
        }
    };

    const handleBackWms = () => {
        setActiveStepWms((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAccept = () => {
        const NewLayerCreated = InitmapConfig.MergeLayerPropertyToStandardObject(dataSource, layerSetting, layerViewSetting, layerFilterSetting);
        props.handleAddNewLayer(NewLayerCreated);
        props.closeModal();
    }

    //--- Shape file
    const handleNextShapeFile = (step) => {
        showLoading(true);
        let newSkipped = skipped;
        if (isStepSkipped(activeStepShapeFile)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStepShapeFile);
        }
        switch (step) {
            case 0: {
                ImportShapeFileStep1(valueShapeFileStep_1).then((res) => {   
                    if (res && res.content && res.content.data) {
                        const reponse = res.content.data;
                        setValueShapeFileStep_2({ RootFolderName: reponse.RootFolderName, ShpFileName: reponse.ShpFileName, TableName: reponse.TableName })
                        setActiveStepShapeFile((prevActiveStep) => prevActiveStep + 1);
                        setSkipped(newSkipped);
                    }
                    showLoading(false);
                }).catch(err => showLoading(false));
                break;
            }
            case 1: {
                ImportShapeFileStep2(valueShapeFileStep_2).then((res) => {
                    if (res && res.content && res.content.data) {
                        const reponse = res.content.data;
                        setValueShapeFileStep_3({ RootFolderName: reponse.RootFolderName, TableName: reponse.TableName, PlanningId: props.planningId })
                        setActiveStepShapeFile((prevActiveStep) => prevActiveStep + 1);
                        setSkipped(newSkipped);
                    }
                    showLoading(false);
                }).catch(err => showLoading(false));
                break;
            }
            case 2: {
                ImportShapeFileStep3(valueShapeFileStep_3).then((res) => {
                    if (res && res.content && res.content.data) {
                        const reponse = res.content.data;
                        setValueShapeFileStep_4({ RootFolderName: reponse.RootFolderName, TableName: reponse.TableName, StyleName: reponse.StyleName })
                        setActiveStepShapeFile((prevActiveStep) => prevActiveStep + 1);
                        setSkipped(newSkipped);
                    }
                    showLoading(false);
                }).catch(err => showLoading(false));
                break;
            }
            case 3: {
                ImportShapeFileStep4(valueShapeFileStep_4).then((res) => {
                    if (res && res.content && res.content.data) {
                        setActiveStepShapeFile((prevActiveStep) => prevActiveStep + 1);
                        setSkipped(newSkipped);
                        ShowNotification(
                            viVN.Success.ImportShapeFileSuccess,
                            NotificationMessageType.Success
                        );
                        setIsImportShapeFile(false);
                        setIsImportTifFile(false);
                        setIsCompleteImportShapeFile(true);
                    }
                    showLoading(false);
                }).catch(err => showLoading(false));

                break;
            }
            default: {
                setIsImportShapeFile(false);
                setIsImportTifFile(false);
                setIsCompleteImportShapeFile(true);
                showLoading(false);
                break;
            }
        }
    }

    const handleBackShapeFile = () => {
        setActiveStepShapeFile((prevActiveStep) => prevActiveStep - 1);
    };

    const ImportShapeFileStep1 = (valueShapeFileStep_1) => {
        return new Promise((resolve, reject) => {
            importShapeFileAction.ImportShapeFileStep1(valueShapeFileStep_1).then((res) => { resolve(res) },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                });
        })
    }
    const ImportShapeFileStep2 = (valueShapeFileStep_2) => {
        return new Promise((resolve, reject) => {
            importShapeFileAction.ImportShapeFileStep2(valueShapeFileStep_2).then((res) => { resolve(res) },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                });
        })
    }
    const ImportShapeFileStep3 = (valueShapeFileStep_3) => {
        return new Promise((resolve, reject) => {
            importShapeFileAction.ImportShapeFileStep3(valueShapeFileStep_3).then((res) => { resolve(res) },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                });
        })
    }
    const ImportShapeFileStep4 = (valueShapeFileStep_4) => {
        return new Promise((resolve, reject) => {
            importShapeFileAction.ImportShapeFileStep4(valueShapeFileStep_4).then((res) => { resolve(res) },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                });
        })
    }

    //--- Tif file
    const handleNextTifFile = (step) => {
        showLoading(true);
        let newSkipped = skipped;
        if (isStepSkipped(activeStepShapeFile)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStepShapeFile);
        }
        switch (step) {
            case 0: {
                setActiveStepTifFile((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
                break;
            }
            case 1: {
                if (valueTiffFile_2) {
                    ImportTiffFileStep2(valueTiffFile_2).then((res) => {
                        if (res && res.content) {
                            setActiveStepTifFile((prevActiveStep) => prevActiveStep + 5);
                            ShowNotification(
                                viVN.Success.ImportShapeFileSuccess,
                                NotificationMessageType.Success
                            );
                            setSkipped(newSkipped);
                            setIsImportShapeFile(false);
                            setIsImportTifFile(false);
                            setIsCompleteImportShapeFile(true);
                        }
                        showLoading(false);
                    })
                } else {
                    showLoading(false);
                    NotificationService.error("Phải thêm file trước khi nhấn tiếp tục!")
                }
                break;
            }
            default: {
                setIsImportShapeFile(false);
                setIsImportTifFile(false);
                setIsCompleteImportShapeFile(true);
                showLoading(false);
                break;
            }
        }
    }

    const handleBackTifFile = () => {
        setActiveStepTifFile((prevActiveStep) => prevActiveStep - 1);
    };

    const ImportTiffFileStep2 = (valueTiffFile_2) => {
        return new Promise((resolve, reject) => {
            importTiffFileAction.ImportTiffFileStep2(valueTiffFile_2).then((res) => { resolve(res) },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                });
        })
    }
    return (
        <div className="modal-setting-new-layer-container">
            {
                isImportShapeFile
                    ? (
                        <Stepper activeStep={activeStepShapeFile}>
                            {stepShp.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    )
                    : isImportTifFile
                        ? (
                            <Stepper activeStep={activeStepTifFile}>
                                {stepTif.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )
                        : (
                            <Stepper activeStep={activeStepWms}>
                                {stepWms.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )
            }

            <div>
                {activeStepWms === stepWms.length
                    ? (<FinishScreen
                        dataSource={dataSource}
                        layerSetting={layerSetting}
                        handleBack={handleBackWms}
                        handleAccept={handleAccept} />
                    )
                    : (<div className="setting-step-container">
                        <div className="content-container">
                            {
                                isImportShapeFile
                                    ? getStepContentWms(activeStepShapeFile)
                                    : isImportTifFile
                                        ? getStepContentWms(activeStepTifFile)
                                        : getStepContentWms(activeStepWms)
                            }
                        </div>
                        <div className="action-container mt-3 text-right">
                            {
                                isImportShapeFile
                                    ? (
                                        <div className="mt-3">
                                            <Button variant="contained"
                                                disabled={activeStepShapeFile === 0}
                                                onClick={handleBackShapeFile}
                                                className="action-button mr-2" >
                                                Quay lại
                                                </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleNextShapeFile(activeStepShapeFile)}
                                                className="action-button"
                                            >
                                                Tiếp tục
                                                </Button>
                                        </div>
                                    )
                                    : isImportTifFile
                                        ? (
                                            <div className="mt-3">
                                                <Button variant="contained"
                                                    disabled={activeStepTifFile === 0}
                                                    onClick={handleBackTifFile}
                                                    className="action-button mr-2" >
                                                    Quay lại
                                            </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNextTifFile(activeStepTifFile)}
                                                    className="action-button"
                                                >
                                                    Tiếp tục
                                            </Button>
                                            </div>)
                                        : (
                                            <div className="mt-3">
                                                <Button variant="contained"
                                                    disabled={activeStepWms === 0}
                                                    onClick={handleBackWms}
                                                    className="action-button mr-2" >
                                                    Quay lại
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNextWms(activeStepWms)}
                                                    className="action-button mr-2"
                                                    disabled={!isThisStepCompleted(activeStepWms)}
                                                >
                                                    Tiếp tục
                                                </Button>
                                            </div>
                                        )
                            }
                        </div>
                    </div>
                    )}
            </div>
        </div>

    )
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(null,mapDispatchToProps)(ModalLayerSetting);