import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const ImportTiffFileStep1 = (data) => {
  if (!data) return;
  const formData = new FormData();
  data && formData.append("file", data);
  return service
    .post(ApiUrl.Tiff_File_Step_1, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ImportTiffFileStep2 = (data) => {
  if (!data) return;
  const formData = new FormData();
  data && formData.append("file", data);
  return service
    .post(ApiUrl.Tiff_File_Step_2, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
