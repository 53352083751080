/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";
import DateFnsUtils from "@date-io/date-fns";
import dateformat from "dateformat";
import viLocale from "date-fns/locale/vi";

//--- Material Control
import {
  DialogActions,
  TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as userManagementAction from "../../../redux/store/user-management/user-management.store";
import * as config from "../../../common/config";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddUserMamagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    ShowNotificationError,
    GetListUserManagement,
    rowsPerPage,
    setOrder,
    setOrderBy,
    showLoading,
  } = props;
  const [avatar, setAvatar] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [roleLockup, setRoleLockup] = useState();
  const [sex, setSex] = useState(true);
  const [roleId, setRoleId] = useState(null);

  useEffect(() => {
    showLoading(true);
    userManagementAction
      .GetRoleLookupUserManagement()
      .then((res) => {
        if (res && res.content) {
          setRoleLockup(res.content);
          setRoleId(res.content[0].id);
          showLoading(false);
        }
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    showLoading(true);
    let formData = new FormData();
    formData.append("FullName", data.fullName);
    formData.append("Email", data.email);
    formData.append("RoleId", roleId);
    formData.append("DateOfBirth", selectedDate);
    formData.append("Sex", sex);
    formData.append("Address", data.address);
    formData.append("Avatar", "");
    formData.append("file", avatar || null);
    formData.append("Description", data.content);
    formData.append("PhoneNumber", data.phoneNumber);
    userManagementAction
      .CreateUserManagement(formData)
      .then((result) => {
        if (result) {
          setOrder("desc");
          setOrderBy("modifiedDate");
          GetListUserManagement(1, rowsPerPage);
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.UserAddSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const handleChange = (files) => {
    clearErrors("avatar");
    if (files) {
      setAvatar(files[0]);
      setValue("avatar", files[0]);
    } else {
      setError("avatar", { type: "required" });
    }
  };

  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > config.Configs.DefaultCapacityFile) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 5MB!");
    } else if (
      rejectFiles.type === "image/*" &&
      (rejectFiles.type === "image/jpeg" ||
        rejectFiles.type === "image/png" ||
        rejectFiles.type === "image/jpg" ||
        rejectFiles.type === "image/gif")
    ) {
      ShowNotificationError("Ảnh nhập vào không đúng định dạng");
    } else {
      ShowNotificationError("File nhập vào phải là ảnh");
    }
  };

  function handleChangeSelectSex(event) {
    setSex(event.target.value);
  }

  function handleChangeSelectRole(event) {
    setRoleId(event.target.value);
  }

  const handleDateChange = (date) => {
    setSelectedDate(dateformat(date, "yyyy-mm-dd"));
    setValue("tfDate", dateformat(date, "yyyy-mm-dd"));
    clearErrors("tfDate");
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm Người dùng</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Họ và tên</label>
                  <TextField
                    type="text"
                    name="fullName"
                    className="w-100"
                    inputRef={register}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Email<span className="required"></span>
                  </label>
                  <TextField
                    type="text"
                    name="email"
                    className="w-100"
                    inputRef={register({
                      required: true,
                      pattern: /^[a-z][a-z0-9_\.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/,
                    })}
                    error={
                      errors.email &&
                      errors.email.type === "required" &&
                      errors.email.type === "pattern"
                    }
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error">Email không đúng định dạng</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Chức vụ</label>
                  <br />
                  <Select
                    className="w-100"
                    value={roleId}
                    onChange={handleChangeSelectRole}
                  >
                    {roleLockup && roleLockup.length > 0 ? (
                      roleLockup.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No options</MenuItem>
                    )}
                  </Select>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Ngày sinh</label>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={viLocale}
                  >
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      onChange={(date) => date && handleDateChange(date)}
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      fullWidth
                      showTodayButton={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Giới tính</label>
                  <br />
                  <Select
                    className="w-100"
                    value={sex}
                    onChange={handleChangeSelectSex}
                  >
                    <MenuItem value={true}>{"Nam"}</MenuItem>
                    <MenuItem value={false}>{"Nữ"}</MenuItem>
                  </Select>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Số điện thoại</label>
                  <TextField
                    type="text"
                    name="phoneNumber"
                    className="w-100"
                    inputRef={register}
                    onChange={(e) =>
                      setValue(
                        "phoneNumber",
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Địa chỉ</label>
                  <TextField
                    inputRef={register}
                    type="text"
                    name="address"
                    className="w-100"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-dark">Mô tả</label>
              <TextareaAutosize
                name="content"
                rowsMin={3}
                className={"form-control"}
                ref={register}
              />
            </div>
            <div className="form-group">
              <label className="text-dark">
                Ảnh (Ảnh nhập vào nhỏ hơn 5MB)
              </label>
              <DropzoneArea
                acceptedFiles={[
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/gif",
                ]}
                showAlerts={false}
                filesLimit={1}
                getDropRejectMessage={(rejectedFile) =>
                  customMessages(rejectedFile)
                }
                getFileLimitExceedMessage={(filesLimit) =>
                  ShowNotification(
                    `Nhiều nhất ${filesLimit} ảnh`,
                    NotificationMessageType.Error
                  )
                }
                dropzoneText={"Chọn ảnh"}
                onChange={handleChange}
              />
            </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
