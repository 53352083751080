import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListPlanning = (pageIndex, pageSize, sortExpression, name) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  name && params.append("planningName", name);
  return service
    .get(ApiUrl.GetListPlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailPlaning = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetDetailPlaning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanning = () => {
  return service
    .get(ApiUrl.GetLookUpPlanning)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningStatus = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningStatus)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningLevel = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningType = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpDocumentType = () => {
  return service
    .get(ApiUrl.GetLookUpDocumentType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreatePlanning = (data) => {
  const formData = new FormData();
  formData.append("Name", data.Name);
  formData.append("PlanningCode", data.PlanningCode);
  formData.append("Photo", data.Photo);
  formData.append("PlanningTypeId", data.PlanningTypeId);
  formData.append("Place", data.Place);
  formData.append("Order", data.Order);
  formData.append("PlanningAgency", data.PlanningAgency);
  formData.append("ConsultingUnit", data.ConsultingUnit);
  formData.append("Investor", data.Investor);
  formData.append("PlanningStatusId", data.PlanningStatusId);
  formData.append("AgencySubmitted", data.AgencySubmitted);
  formData.append("PlanningUnit", data.PlanningUnit);
  formData.append("PlanningLevelId", data.PlanningLevelId);
  formData.append("Population", data.Population);
  formData.append("Acreage", data.Acreage);
  formData.append("LandForConstruction", data.LandForConstruction);
  formData.append("Report", data.Report);
  formData.append("Note", data.Note);
  formData.append("DocumentTypeId", data.DocumentTypeId);
  data.MapId && formData.append("MapId", data.MapId);
  formData.append("file", data.file);
  formData.append("ApprovalAgencyLevelId", data.ApprovalAgencyLevelId);
  formData.append("isOldPlan", data.isOldPlan);
  data.PlanningDistrictIds.length > 0 && data.PlanningDistrictIds.map(item => formData.append("PlanningDistrictIds", item));
  return service
    .post(ApiUrl.CreatePlanning, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdatePlanning = (data) => {
  const formData = new FormData();
  formData.append("Id", data.Id);
  formData.append("Name", data.Name);
  formData.append("PlanningCode", data.PlanningCode);
  formData.append("Photo", data.Photo);
  formData.append("PlanningTypeId", data.PlanningTypeId);
  formData.append("Place", data.Place);
  data.DistrictId && formData.append("DistrictId", data.DistrictId);
  formData.append("Order", data.Order);
  formData.append("PlanningAgency", data.PlanningAgency);
  formData.append("ConsultingUnit", data.ConsultingUnit);
  formData.append("Investor", data.Investor);
  formData.append("PlanningStatusId", data.PlanningStatusId);
  formData.append("AgencySubmitted", data.AgencySubmitted);
  formData.append("PlanningUnit", data.PlanningUnit);
  formData.append("PlanningLevelId", data.PlanningLevelId);
  formData.append("Population", data.Population);
  formData.append("Acreage", data.Acreage);
  formData.append("LandForConstruction", data.LandForConstruction);
  formData.append("Report", data.Report);
  formData.append("Note", data.Note);
  formData.append("DocumentTypeId", data.DocumentTypeId);
  data.MapId && formData.append("MapId", data.MapId);
  formData.append("ApprovalAgencyLevelId", data.ApprovalAgencyLevelId);
  data.PlanningDistrictIds.length > 0 && data.PlanningDistrictIds.map(item => formData.append("DistrictIds", item));
  formData.append("file", data.file)
  return service
    .post(ApiUrl.UpdatePlanning, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeletePlanning = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .postParams(ApiUrl.DeletePlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const ApprovalAgencyLevel = () => {
  return service
    .get(ApiUrl.ApprovalAgencyLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningRelationshipType = () => {
  return service
    .get(ApiUrl.PlanningRelationshipType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningRelationshipTypeById = (id) => {
  const params = new URLSearchParams();
  params.append("planningId", id);
  return service
    .get(ApiUrl.PlanningRelationshipTypeById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const PlanningApprovedById = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.PlanningApprovedById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


export const GetLookupDistrict = () => {
  return service
    .get(ApiUrl.GetLookupDistrict)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PutPlanningReletionship = (requestBody, planningId) => {
  const params = new URLSearchParams();
  params.append("planningId", planningId);
  return service
    .put(ApiUrl.PutPlanningRelationship, requestBody, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetAllBoundaries = () => {
  return service
    .get(ApiUrl.GetAllBoundaries)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const CheckExistedPlanning = (planningCode) => {
  const params = new URLSearchParams();
  params.append("planningCode", planningCode);
  return service
    .get(ApiUrl.CheckExistedPlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


