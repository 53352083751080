import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();
export const GetListCommune = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("name", name);
    return service.get("/api/admin/Administrative/GetListCommune", params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailCommune = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.GetDetailCommune.trim(), params).then(res => { return res }).catch(err => { throw err });
}

export const CreateCommune = (body) => {
    return service.post(ApiUrl.CreateCommune, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateCommune = (body) => {
    return service.post(ApiUrl.UpdateCommune, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteCommune = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.postParams(ApiUrl.DeleteCommune, params).then(res => { return res }).catch(err => { throw err });
}