import axios from "axios";
import * as apiConfig from "./api-config";
import * as configuration from "../utils/configuration";
import * as Page500ErrorTypes from '../common/error-types-500';

configuration.setConfiguration(
  configuration.ApiServerKey.APP_API_ROOT,
  apiConfig.api
);
const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

const isHandlerEnabled = true;

const requestHandler = (request) => {
  if (isHandlerEnabled) {
    // request.headers.common["Accept"] = "application/json";
    request.headers.common["Content-Type"] = "application/json; charset=utf-8";
    request.headers.common["Accept"] =
      "application/json, text/javascript, */*; q=0.01";
    request.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  let userInfo = configuration.getCookies(configuration.TokenKey.token);
  if (userInfo) {
    request.headers.common[
      "Authorization"
    ] = `${configuration.TokenPrefix} ${userInfo}`;
  }

  return request;
};

const successHandler = (response, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Success Handler
  }

  return response;
};

const errorHandler = (error, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Error Handler
  }

  return Promise.reject({  ...(error.response ? error.response.data : { errorType: Page500ErrorTypes.UnhandleException, errorMessage: "UnhandleException" }) });
};

export default class Service {
  constructor(namespace) {
    this.namespace = namespace;
    this.axios = axios.create({
      baseURL: apiRoot,
      responseType: "json",
    });

    //Enable request interceptor
    this.axios.interceptors.request.use(
      (request) => requestHandler(request, isHandlerEnabled),
      (error) => errorHandler(error, isHandlerEnabled)
    );

    //Response and Error handler
    this.axios.interceptors.response.use(
      (response) => successHandler(response, isHandlerEnabled),
      (error) => errorHandler(error, isHandlerEnabled)
    );
  }

  /**
   * Get Http Request
   * @param {any} action
   */
  get(action, params) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "GET",
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Post Http Request
   * @param {any} action
   * @param {any} params
   */
  postParams(action, params, body) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "POST",
          data: body,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Post Http Request
   * @param {any} action
   * @param {any} params
   */
  post(action, params) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(action, {
          method: "POST",
          data: params,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Put Http Request
   * @param {any} action
   * @param {any} params
   */
  put(action, requestBody, params = null) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + '?' + params : action, {
          method: "PUT",
          data: requestBody,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }
}
