/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../../core/app.store";
import * as documentAction from "../../../redux/store/document/document.store";
import DeleteDialog from "../../../components/dialog-delete/dialog-delete.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as configCommon from "../../../common/config";
import * as config from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import "./document-settings.scss";

//--- Material Control
import {
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";

//--- Material Icon
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AttachFile from "@material-ui/icons/AttachFile";
import Description from "@material-ui/icons/Description";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

//--- Drag
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {
  NotificationMessageType,
  Transition,
} from "../../../utils/configuration";
import FileInputComponent from "../../../components/file-input/file-input.view";

//--- DropzoneArea
import { DropzoneArea } from "material-ui-dropzone";
import FileViewer from "react-file-viewer";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 10,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    item,
    setDocument,
    GetAllDocumentByPlanning,
    ...other
  } = props;

  return (
    <TreeItem
      onClick={() => {
        if (item.isLoaded) return;
        if (item.typeName === DOCUMENT_TYPE.FOLDER) {
          GetAllDocumentByPlanning(item.id);
        } else {
          setDocument(item);
        }
      }}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography className={classes.labelText}>{labelText}</Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#1075BD",
  },
  title: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function IconFolder() {
  return (
    <img
      src={require("../../../assets/icon/folder.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function IconPDF() {
  return (
    <img
      src={require("../../../assets/icon/pdf.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function IconDOCX() {
  return (
    <img
      src={require("../../../assets/icon/microsoft-word.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function IconXLSX() {
  return (
    <img
      src={require("../../../assets/icon/excel.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function IconImages() {
  return (
    <img
      src={require("../../../assets/icon/picture.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function IconOtherFile() {
  return (
    <img
      src={require("../../../assets/icon/paper.svg")}
      alt="Folder"
      style={{ width: "16px", height: "16px", marginTop: "-2px" }}
      className="mr-2"
    />
  );
}

function FolderComponent(
  listData,
  onCreateFolder,
  onCreateFile,
  onEditItem,
  onDeleteItem,
  GetAllDocumentByPlanning,
  setDocument
) {
  const itemList = listData && listData.length > 0 ? listData : [];

  return (
    <div>
      {itemList &&
        itemList.length > 0 &&
        itemList.map((item) => (
          <div key={item.id}>
            <ContextMenuTrigger id={`treeLevel-${item.id}`}>
              {item.typeName === DOCUMENT_TYPE.FOLDER ? (
                <StyledTreeItem
                  item={item}
                  setDocument={setDocument}
                  GetAllDocumentByPlanning={GetAllDocumentByPlanning}
                  nodeId={`nodeId-${item.id}`}
                  labelText={item.name}
                  labelIcon={IconFolder}
                >
                  {item.itemList &&
                    item.itemList.length > 0 &&
                    FolderComponent(
                      item.itemList,
                      onCreateFolder,
                      onCreateFile,
                      onEditItem,
                      onDeleteItem,
                      GetAllDocumentByPlanning,
                      setDocument
                    )}
                  <div></div>
                </StyledTreeItem>
              ) : (
                  <StyledTreeItem
                    item={item}
                    setDocument={setDocument}
                    GetAllDocumentByPlanning={GetAllDocumentByPlanning}
                    nodeId={`nodeId-${item.id}`}
                    labelText={item.name}
                    labelIcon={
                      item.extension === "PDF"
                        ? IconPDF
                        : item.extension === "DOCX"
                          ? IconDOCX
                          : item.extension === "XLSX"
                            ? IconXLSX
                            : item.extension === "PNG" ||
                              item.extension === "JPEG" ||
                              item.extension === "JPG"
                              ? IconImages
                              : IconOtherFile
                    }
                  >
                    {item.itemList &&
                      item.itemList.length > 0 &&
                      FolderComponent(
                        item.itemList,
                        onCreateFolder,
                        onCreateFile,
                        onEditItem,
                        onDeleteItem,
                        GetAllDocumentByPlanning,
                        setDocument
                      )}
                  </StyledTreeItem>
                )}
            </ContextMenuTrigger>

            <ContextMenu id={`treeLevel-${item.id}`}>
              {item.typeName && item.typeName === DOCUMENT_TYPE.FOLDER && (
                <div>
                  <MenuItem data={item} onClick={onCreateFolder}>
                    <CreateNewFolderIcon className="mr-2" /> Thư mục mới
                  </MenuItem>
                  <MenuItem data={item} onClick={onCreateFile}>
                    <InsertDriveFileIcon className="mr-2" /> Thêm file
                  </MenuItem>
                </div>
              )}
              <MenuItem data={item} onClick={onEditItem}>
                <BorderColorIcon className="mr-2" /> Đổi tên
              </MenuItem>
              <MenuItem data={item} onClick={onDeleteItem}>
                <DeleteIcon className="mr-2" /> Xóa
              </MenuItem>
              <MenuItem divider />
            </ContextMenu>
          </div>
        ))}
    </div>
  );
}

const DOCUMENT_TYPE = {
  FOLDER: "Folder",
  FILE: "File",
};

const ShowNotificationError = (messages) => {
  ShowNotification(messages, NotificationMessageType.Error);
};

function DocumentSettings(props) {
  const {
    showLoading,
    isShowDialog,
    onCloseDialog,
    planningId,
    planningName,
  } = props;
  const [folderList, setFolderList] = useState([]);
  const [files, setFiles] = useState([]);
  const [document, setDocument] = useState(null);
  // const [isShowPreview, setIsShowPreview] = useState(null);
  useEffect(() => {
    GetAllDocumentByPlanning();
  }, []);

  const GetAllDocumentByPlanning = (parentId = 0) => {
    showLoading(true);
    documentAction
      .GetAllDocumentByPlanning(planningId, parentId)
      .then((res) => {
        if (parentId === 0) {
          setFolderList(
            res && res.content
              ? res.content.map((item) => {
                return {
                  ...item,
                  isLoaded: false,
                  itemList: [],
                };
              })
              : []
          );
        } else {
          folderList &&
            folderList.length > 0 &&
            loopGetDataByParentId(
              folderList,
              res && res.content ? res.content : []
            );
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const CreateFolder = (data) => {
    return new Promise((resolve, reject) => {
      documentAction
        .CreateFolder(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const CreateFile = (data) => {
    return new Promise((resolve, reject) => {
      documentAction
        .CreateFile(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const ReNameDocument = (data) => {
    return new Promise((resolve, reject) => {
      documentAction
        .RenameDocument(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const RemoveDocument = (id) => {
    return new Promise((resolve, reject) => {
      documentAction
        .RemoveDocument(id)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [openAddFolderDialog, setOpenAddFolderDialog] = useState(false);
  const [openAddFileDialog, setOpenAddFileDialog] = useState(false);
  const [openEditFolderDialog, setOpenEditFolderDialog] = useState(false);
  const [openDeleteFolderDialog, setOpenDeleteFolderDialog] = useState(false);

  const [currentItem, setCurrentItem] = useState();

  function onCreateFolder(e, data) {
    setCurrentItem(data);
    setOpenAddFolderDialog(true);
  }

  function onCreateFile(e, data) {
    setCurrentItem(data);
    setOpenAddFileDialog(true);
  }

  function onEditFolder(e, data) {
    setCurrentItem(data);
    setOpenEditFolderDialog(true);
  }

  function onDeleteFolder(e, data) {
    setCurrentItem(data);
    setOpenDeleteFolderDialog(true);
  }

  function onCloseAddOrEditDialog() {
    setCurrentItem(null);
    setOpenAddFolderDialog(false);
    setOpenAddFileDialog(false);
    setOpenEditFolderDialog(false);
    setOpenDeleteFolderDialog(false);
  }

  function onSubmitCreateFolder(data) {
    if (!data || !currentItem) {
      return;
    }
    showLoading(true);
    CreateFolder({
      name: data.folderName,
      ordinal: 0,
      parentId: currentItem.id,
      typeId: 0,
      planningId: planningId,
    })
      .then((res) => {
        if (res && res.content && res.content.data) {
          let value = res.content.data;
          if (currentItem.parentId === -1) {
            setFolderList([
              ...folderList,
              {
                id: value.id,
                planningId: planningId,
                name: value.name,
                parentId: 0,
                ordinal: value.ordinal,
                type: value.type,
                typeName: "Folder",
                isLoaded: false,
                itemList: [],
              },
            ]);
          } else {
            setFolderList([
              ...loopCreateFolder(folderList, currentItem, value),
            ]);
          }
        }
        showLoading(false);
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
        showLoading(false);
      });
    onCloseAddOrEditDialog();
  }

  function onSubmitEditFolder(data) {
    if (!data || !currentItem) {
      return;
    }
    showLoading(true);
    documentAction
      .RenameDocument({
        documentId: currentItem.id,
        newDocumentName: data.folderName,
        planningId: planningId,
        type: currentItem.type === 0 ? 0 : 1,
      })
      .then((res) => {
        if (res && res.content && res.content.data) {
          setFolderList(
            loopEditFolder(folderList, currentItem, res.content.data)
          );
        }
        showLoading(false);
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
        showLoading(false);
      });
    onCloseAddOrEditDialog();
  }
  function onSubmitCreateFile() {
    if (!currentItem || files.length === 0) return;
    showLoading(true);
    CreateFile({
      Ordinal: 0,
      ParentId: currentItem.id,
      TypeId: 1,
      PlanningId: planningId,
      files: files,
    })
      .then((res) => {
        if (
          res &&
          res.content &&
          res.content.data &&
          res.content.data.length > 0
        ) {
          loopCreateFile(folderList, currentItem, res.content.data);
          onCloseAddOrEditDialog();
        }
        showLoading(false);
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
        showLoading(false);
      });
  }

  function onSuccessDeleteFolder() {
    if (!currentItem) {
      return;
    }
    showLoading(true);
    RemoveDocument(currentItem.id)
      .then((res) => {
        res && loopDeleteFolder(folderList, currentItem);
        showLoading(false);
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
        showLoading(false);
      });
    onCloseAddOrEditDialog();
  }

  function loopGetDataByParentId(folderList, data) {
    let folderListTemp = [...folderList];
    for (let index = 0; index < folderListTemp.length; index++) {
      data.filter((item) => {
        if (folderListTemp[index].id === item.parentId) {
          if (folderListTemp[index].itemList.length < data.length) {
            folderListTemp[index].isLoaded = true;
            folderListTemp[index].itemList.push({
              ...item,
              itemList: [],
            });
          }
        } else {
          folderListTemp[index].type === 0 &&
            loopGetDataByParentId(folderListTemp[index].itemList, data);
        }
      });
    }
    setFolderList(folderListTemp);
    return folderListTemp;
  }

  function loopCreateFolder(folderList, data, value) {
    let folderListTemp = folderList;

    for (let index = 0; index < folderListTemp.length; index++) {
      if (folderListTemp[index].id === data.id) {
        folderListTemp[index].isLoaded = true;
        folderListTemp[index].itemList.push({
          id: value.id,
          planningId: planningId,
          name: value.name,
          parentId: value.parentId,
          ordinal: value.ordinal,
          type: value.type,
          isLoaded: false,
          typeName: "Folder",
          itemList: [],
        });
        break;
      } else {
        folderListTemp[index].type === 0 &&
          loopCreateFolder(folderListTemp[index].itemList, data, value);
      }
    }
    return folderListTemp;
  }

  function loopCreateFile(folderList, data, value) {
    let folderListTemp = folderList;
    for (let index = 0; index < folderListTemp.length; index++) {
      if (folderListTemp[index].id === data.id) {
        folderListTemp[index].isLoaded = true;
        value.filter((item) =>
          folderListTemp[index].itemList.push({ ...item, typeName: "File" })
        );
        break;
      } else {
        folderListTemp[index].type === 0 &&
          loopCreateFile(folderListTemp[index].itemList, data, value);
      }
    }
    setFolderList(folderListTemp);
    return folderListTemp;
  }

  function loopEditFolder(folderList, data, respone) {
    let folderListTemp = [...folderList];

    for (let index = 0; index < folderListTemp.length; index++) {
      if (folderListTemp[index].id === data.id) {
        folderListTemp[index].name = respone.name;
        if (data.typeName === "Folder") {
          folderListTemp[index].itemList = [];
          GetAllDocumentByPlanning(data.id)
            .then(
              (res) =>
                res &&
                res.content &&
                res.content.map((item) =>
                  folderListTemp[index].itemList.push({ ...item })
                )
            )
            .catch((err) => console.log(err));
        } else {
          folderListTemp[index].path = respone.path;
        }
        break;
      } else {
        folderListTemp[index].type === 0 &&
          loopEditFolder(folderListTemp[index].itemList, data, respone);
      }
    }
    return folderListTemp;
  }

  function loopDeleteFolder(folderList, data) {
    let folderListTemp = [...folderList];
    for (let index = 0; index < folderListTemp.length; index++) {
      findNested(folderListTemp[index], folderListTemp, data.id, index);
    }
    setFolderList(folderListTemp);
    return folderListTemp;
  }

  const findNested = (object, parent, value, i) => {
    if (object.id === value) {
      parent.splice(i, 1);
    }
    if (object && object.itemList && object.itemList.length > 0) {
      for (let j = 0; j < object.itemList.length; j++) {
        findNested(object.itemList[j], object.itemList, value, j);
      }
    }
  };

  // const customMessages = (rejectFiles) => {
  //   if (rejectFiles.size > configCommon.Configs.fileDocument) {
  //     ShowNotificationError("Kích thước file phải nhỏ hơn 100MB!");
  //   } else if (
  //     rejectFiles.type === ".doc" ||
  //     rejectFiles.type === ".docx" ||
  //     rejectFiles.type === ".ppt" ||
  //     rejectFiles.type === ".pdf" ||
  //     rejectFiles.type === ".xlsx" ||
  //     rejectFiles.type === ".tif" ||
  //     rejectFiles.type === ".dwg" ||
  //     rejectFiles.type === ".dng" ||
  //     rejectFiles.type === ".png" ||
  //     rejectFiles.type === ".jpg" || rejectFiles.type === "xls" || rejectFiles.type === "pptx"
  //   ) {
  //     ShowNotificationError(
  //       "File nhập vào không đúng định dạng doc, docx, ppt, pdf, xlsx, tif, dwg, dng, png, jpg ,xls, pptx"
  //     );
  //   } else {
  //     ShowNotificationError("File nhập vào phải là file tài liệu");
  //   }
  // };

  // const handleChange = (files) => {
  //   if (files) {
  //     setFiles(files);
  //   }
  // };

  // const handlePreviewIcon = (fileObject, classes) => {
  //   const { type } = fileObject.file;
  //   const iconProps = {
  //     className: classes.image,
  //   };

  //   switch (type) {
  //     case "application/msword":
  //     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
  //       return <Description {...iconProps} />;
  //     case "application/pdf":
  //       return <PictureAsPdf {...iconProps} />;
  //     default:
  //       return <AttachFile {...iconProps} />;
  //   }
  // };

  const mainHeight = window.innerHeight - 64;

  return (
    <Dialog
      fullScreen
      open={isShowDialog}
      onClose={onCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Thiết lập hồ sơ ({planningName})
          </Typography>
          <Button color="inherit" onClick={onCloseDialog}>
            <CloseIcon />
          </Button>
        </Toolbar>
      </AppBar>

      <div className="container-fluid pl-0 pr-0">
        <div className="row no-gutters">
          <div
            className="col-12 col-md-3 col-lg-3"
            style={{ minHeight: mainHeight, maxHeight: mainHeight }}
          >
            <div className="document-settings p-2 border-right h-100">
              <ContextMenuTrigger id="root-folder">
                <TreeView
                  className={classes.root}
                  defaultExpanded={["1"]}
                  defaultCollapseIcon={<KeyboardArrowDownIcon />}
                  defaultExpandIcon={<KeyboardArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                >
                  {folderList && folderList.length > 0 ? (
                    FolderComponent(
                      folderList,
                      onCreateFolder,
                      onCreateFile,
                      onEditFolder,
                      onDeleteFolder,
                      GetAllDocumentByPlanning,
                      setDocument
                    )
                  ) : (
                      <div className="mt-3 d-flex align-items-center justify-content-center">
                        <InfoIcon className="text-info mr-1" /> Click chuột phải
                      để tạo thư mục mới
                      </div>
                    )}
                </TreeView>

                <ContextMenu id="root-folder">
                  <MenuItem data={{ parentId: -1 }} onClick={onCreateFolder}>
                    <CreateNewFolderIcon className="mr-2" /> Thư mục mới
                  </MenuItem>
                </ContextMenu>
              </ContextMenuTrigger>
            </div>
          </div>
          <div
            className="col-12 col-md-9 col-lg-9"
            style={{ minHeight: mainHeight, maxHeight: mainHeight }}
          >
            {document ? (
              <FileViewer
                key={document.id}
                fileType={document.extension.toLowerCase()}
                filePath={config.APIUrlDefault + document.path}
              />
            ) : (
                <div className="mt-4 d-flex align-items-center justify-content-center">
                  <CancelPresentationIcon className="text-danger mr-1" /> Không có
                file để hiển thị
                </div>
              )}
          </div>
        </div>
      </div>

      {openAddFolderDialog && (
        <Dialog
          open={openAddFolderDialog}
          onClose={onCloseAddOrEditDialog}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle disableTypography className="border-bottom">
            <Typography variant="h6">Thêm thư mục</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseAddOrEditDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form
            onSubmit={handleSubmit(onSubmitCreateFolder)}
            autoComplete="off"
          >
            <DialogContent className="pt-4 pb-4">
              <TextField
                fullWidth
                type="text"
                name="folderName"
                placeholder="Nhập tên thư mục"
                error={
                  errors.folderName && errors.folderName.type === "required"
                }
                inputRef={register({ required: true })}
              />
              {errors.folderName && errors.folderName.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </DialogContent>

            <DialogActions className="border-top">
              <Button
                onClick={onCloseAddOrEditDialog}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {openAddFileDialog && (
        <Dialog
          open={openAddFileDialog}
          onClose={onCloseAddOrEditDialog}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle disableTypography className="border-bottom">
            <Typography variant="h6">Thêm mới file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseAddOrEditDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmitCreateFile)} autoComplete="off">
            <DialogContent className="pt-4 pb-4">
              <div className="form-group">
                <label className="text-dark">
                  Chọn file (File nhập vào nhỏ hơn 100MB)
                </label>
                <FileInputComponent
                  onChangeFiles={setFiles}
                  maxFileSize={configCommon.Configs.fileDocument}
                  filesLimit={100}
                  sizePriority={true}
                  acceptedFiles={[
                    "doc",
                    "docx",
                    "ppt",
                    "pdf",
                    "xlsx",
                    "tif",
                    "dwg",
                    "dng",
                    "png",
                    "jpg",
                    "jpeg",
                    "xls",
                    "pptx"
                  ]}
                  placeholder="Chọn file"
                />
              </div>
            </DialogContent>

            <DialogActions className="border-top">
              <Button
                type="button"
                onClick={onCloseAddOrEditDialog}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              {files.length > 0 && (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Lưu
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )}

      {openEditFolderDialog && currentItem && (
        <Dialog
          open={openEditFolderDialog}
          onClose={onCloseAddOrEditDialog}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle disableTypography className="border-bottom">
            <Typography variant="h6">Đổi tên {currentItem.name}</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseAddOrEditDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmitEditFolder)} autoComplete="off">
            <DialogContent className="pt-4 pb-4">
              <TextField
                fullWidth
                type="text"
                name="folderName"
                placeholder="Nhập tên thư mục"
                defaultValue={currentItem.name}
                error={
                  errors.folderName && errors.folderName.type === "required"
                }
                inputRef={register({ required: true })}
              />
              {errors.folderName && errors.folderName.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </DialogContent>

            <DialogActions className="border-top">
              <Button
                onClick={onCloseAddOrEditDialog}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {openDeleteFolderDialog && currentItem && (
        <DeleteDialog
          isOpen={openDeleteFolderDialog}
          onClose={onCloseAddOrEditDialog}
          onSuccess={onSuccessDeleteFolder}
          header={"Xóa thư mục"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DocumentSettings);
