export const ApiUrl = {
    //--- Account
    Login: "/api/Account/Login",
    Register: "/api/Account/Register",
    ForgotPassword: "/api/Account/ForgotPassword",
    ResetPassword: "​/api​/Account​/ResetPassword",

    //--- Email Generated
    GetListEmailGenerated: "/api/admin/EmailGenerated/GetListEmailGenerated",
    GetDetailEmailGenerated: "/api/admin/EmailGenerated/GetDetailEmailGenerated",

    //--- Email Template
    GetListEmailTemplates: "/api/admin/EmailTemplate/GetListEmailTemplates",
    GetDetailEmailTemplate: "/api/admin/EmailTemplate/GetDetailEmailTemplate",
    CreateEmailTemplate: "/api/admin/EmailTemplate/CreateEmailTemplate",
    UpdateEmailTemplate: "/api/admin/EmailTemplate/UpdateEmailTemplate",
    DeleteEmailTemplate: "/api/admin/EmailTemplate/DeleteEmailTemplate",

    //--- Contact Management
    GetListContactManagement: "/api/admin/EmailGenerated/GetListContactToAdmin",
    GetContactDetail: "/api/admin/EmailGenerated/GetDetailContactToAdmin",

    //--- Role Management
    GetListRoleManagement: "/api/admin/Role/GetListRole",
    GetDetailRoleManagement: "/api/admin/Role/GetDetailRole",
    CreateRoleManagement: "/api/admin/Role/CreateRole",
    UpdateRoleManagement: "/api/admin/Role/UpdateRole",
    DeleteRoleManagement: "/api/admin/Role/DeleteRole",

    //--- Map
    CreateMap: "/api/admin/Map/create-map",
    GetMapById: "/api/admin/Map/get-map-by-id",
    GetAllBaseMapSetting: "/api/admin/BaseMapSetting/get-all",
    GetPgSchema: "/api/admin/PgTable/get-pg-schema",
    GetPageByTable: "/api/admin/PgTable/get-pg-table",
    UpdateMap: "/api/admin/Map/update-map",
    GetPgTable: "/api/admin/PgTable/get-pg-table",

    //--- Planning
    GetListPlanning: "/api/admin/Planning/GetListPlanning",
    GetLookUpPlanning: "/api/admin/Planning/GetLookUpPlanning",
    GetLookUpPlanningStatus: "/api/admin/Planning/GetLookUpPlanningStatus",
    GetLookUpPlanningLevel: "/api/admin/Planning/GetLookUpPlanningLevel",
    GetLookUpPlanningType: "/api/admin/Planning/GetLookUpPlanningType",
    GetLookUpDocumentType: "/api/admin/Planning/GetLookUpDocumentType",
    GetDetailPlaning: "/api/admin/Planning/GetDetailPlaning",
    CreatePlanning: "/api/admin/Planning/CreatePlanning",
    UpdatePlanning: "/api/admin/Planning/UpdatePlanning",
    DeletePlanning: "/api/admin/Planning/DeletePlanning",
    PlanningRelationshipType:
        "/api/admin/Planning/get-all-planning-relationship-type",
    PlanningRelationshipTypeById:
        "/api/admin/Planning/get-all-planning-relationship-by-planning-id",
    PlanningApprovedById:
        "/api/admin/Planning/get-look-up-approved-planning-by-id",
    PutPlanningRelationship:
        "/api/admin/Planning/update-multiple-planning-relationship",
    GetAllBoundaries: "/api/admin/Planning/get-all-boundaries",
    CheckExistedPlanning: "/api/admin/Planning/CheckExistedPlanning",

    //--- Consultant Community
    GetListConsultCommunity:
        "/api/admin/ConsultantCommunity/GetListConsultCommunity",
    GetDetailConsultCommunity:
        "/api/admin/ConsultantCommunity/GetDetailConsultCommunity",
    CreateConsultCommunity:
        "/api/admin/ConsultantCommunity/CreateConsultCommunity",
    UpdateConsultCommunity:
        "/api/admin/ConsultantCommunity/UpdateConsultCommunity",
    DeleteConsultCommunity:
        "/api/admin/ConsultantCommunity/DeleteConsultCommunity",
    GetConsultCommunityStatusLookup:
        "/api/admin/ConsultantCommunity/GetConsultCommunityStatusLookup",
    GetConsultCommunityByPlanning:
        "/api/admin/ConsultantCommunity/GetConsultCommunityByPlanning",

    //--- Statement
    GetListStatement: "/api/admin/Statement/GetListStatement",
    // GetDetailStatement: "​/api​/admin​/Statement​/GetStatementByPlanning",
    CreateStatement: "/api/admin/Statement/CreateStatement",
    UpdateStatement: "/api/admin/Statement/UpdateStatement",
    DeleteStatement: "/api/admin/Statement/DeleteStatement",
    GetStatementStatusLookup: "/api/admin/Statement/GetStatementStatusLookup",
    GetListServiceLink: "/api/admin/Statement/GetListServiceLink",
    SearchStatement: "/api/admin/Statement/search-statement-by",

    // Home page
    GetListHomePage: "/api/admin/HomePage/GetListHomePage",
    GetDetailHomePage: "/api/admin/HomePage/GetDetailHomePage",
    CreateHomePage: "/api/admin/HomePage/CreateHomePage",
    UpdateHomePage: "/api/admin/HomePage/UpdateHomePage",
    DeleteHomePage: "/api/admin/HomePage/DeleteHomePage",
    DeleteAvatar: "/api/admin/HomePage/DeleteAvatar",

    // User Management
    GetListUserManagement: "/api/admin/User/GetUserManagement",
    GetDetailUserManagement: "/api/admin/User/GetUserDetail",
    CreateUserManagement: "/api/admin/User/CreateUser",
    UpdateUserManagement: "/api/admin/User/UpdateUser",
    DeleteUserManagement: "/api/admin/HomePage/DeleteHomePage",
    ActiveUserManagement: "/api/admin/User/ActiveUser",
    ResetPasswordUserManagement: "/api/admin/User/ResetPassword",
    GetRoleLookupUserManagement: "/api/admin/Role/GetRoleLookup",
    ActiveUser: "/api/admin/User/ActiveUser",
    DeActiveUser: "/api/admin/User/DeActiveUser",

    //ApprovalAgencyLevel
    ApprovalAgencyLevel:
        "/api/admin/ApprovalAgencyLevel/get-approval-agency-level",
    GetLookupDistrict: "/api/admin/Administrative/GetLookupDistrict",

    //Document
    GetAllDocumentByPlanning: "/api/admin/Document/GetAllDocumentByPlanning",
    CreateFolder: "/api/admin/Document/CreateFolder",
    ReNameDocument: "/api/admin/Document/RenameDocument",
    CreateFile: "/api/admin/Document/CreateFile",
    RemoveDocument: "/api/admin/Document/RemoveDocument",
    GetAllFolder: "/api/admin/Document/ListAllFolder",
    GetDocumentByFolder: "/api/admin/Document/GetDocumentByFolder",

    // CommuneManagement
    GetListCommune: "/api/admin/Administrative/GetListCommune",
    GetDetailCommune: "/api/admin/Administrative/GetDetailCommune",
    CreateCommune: "/api/admin/Administrative/CreateCommune",
    UpdateCommune: "/api/admin/Administrative/UpdateCommune",
    DeleteCommune: "/api/admin/Administrative/DeleteCommune",
    GetCommuneByDistrictId: "/api/admin/Administrative/GetLookupCommune",
    GetDistrictByProvinceId: "/api/admin/Administrative/GetLookupDistrict",

    // DistrictManagement
    GetListDistrict: "/api/admin/Administrative/GetListDistrict",
    GetDetailDistrict: "/api/admin/Administrative/GetDetailDistrict",
    GetLookupDistrict: "/api/admin/Administrative/GetLookupDistrict",
    CreateDistrict: "/api/admin/Administrative/CreateDistrict",
    UpdateDistrict: "/api/admin/Administrative/UpdateDistrict",
    DeleteDistrict: "/api/admin/Administrative/DeleteDistrict",

    // ProvinceManagement
    GetListProvince: "/api/admin/Administrative/GetListProvince",
    GetDetailProvince: "/api/admin/Administrative/GetDetailProvince",
    CreateProvince: "/api/admin/Administrative/CreateProvince",
    UpdateProvince: "/api/admin/Administrative/UpdateProvince",
    DeleteProvince: "/api/admin/Administrative/DeleteProvince",
    GetLookupProvince: "/api/admin/Administrative/GetLookupProvince",

    //Link Group Management
    GetListLinkGroup: "/api/admin/Administrative/GetListLinkGroup",
    GetDetailLinkGroup: "/api/admin/Administrative/GetDetailLinkGroup",
    CreateLinkGroup: "/api/admin/Administrative/CreateLinkGroup",
    UpdateLinkGroup: "/api/admin/Administrative/UpdateLinkGroup",
    DeleteLinkGroup: "/api/admin/Administrative/DeleteLinkGroup",

    //Service Link Management
    GetListServiceLink: "/api/admin/Administrative/GetListLinkService",
    GetDetailServiceLink: "/api/admin/Administrative/GetDetailLinkService",
    CreateServiceLink: "/api/admin/Administrative/CreateLinkService",
    UpdateServiceLink: "/api/admin/Administrative/UpdateLinkService",
    DeleteServiceLink: "/api/admin/Administrative/DeleteLinkService",
    GetLookupLinkGroup: "/api/admin/Administrative/GetLookupLinkGroup",

    //Userlog
    GetAllLogHistory: "/api/admin/LogHistory/GetAllLogHistory",

    //Land Type
    GetListLandType: "/api/admin/Land/GetListLandType",
    LookupLandType: "/api/admin/Land/LookupLandType",
    GetLandTypeById: "/api/admin/Land/GetLandTypeById",
    CreateLandType: "/api/admin/Land/CreateLandType",
    UpdateLandType: "/api/admin/Land/UpdateLandType",
    DeleteLandType: "/api/admin/Land/DeleteLandType",

    //Land Type Detail
    GetListLandTypeDetail: "/api/admin/Land/GetListLandTypeDetail",
    GetLandTypeDetailById: "/api/admin/Land/GetLandTypeDetailById",
    CreateLandTypeDetail: "/api/admin/Land/CreateLandTypeDetail",
    UpdateLandTypeDetail: "/api/admin/Land/UpdateLandTypeDetail",
    DeleteLandTypeDetail: "/api/admin/Land/DeleteLandTypeDetail",

    //Opinion Form
    CreateFormTemplate: "/api/admin/ConsultantCommunity/CreateFormTemplate",
    UpdateFormTemplate: "/api/admin/ConsultantCommunity/UpdateFormTemplate",
    GetListFormTemplate: "/api/admin/ConsultantCommunity/GetListFormTemplate",
    GetDetailFormTemplate: "/api/admin/ConsultantCommunity/GetDetailFormTemplate",
    DeleteFormTemplate: "/api/admin/ConsultantCommunity/DeleteFormTemplate",
    GetLookupFormExample: "/api/admin/ConsultantCommunity/GetLookupFormExample",

    GetDetailFeedback: "/api/admin/ConsultantCommunityAdmin/GetDetailFeedback",
    SaveReply: "/api/admin/ConsultantCommunityAdmin/SaveReply",

    //utils-shape-file
    Shape_File_Step_1: "/api/admin/Utils/step-1-import-and-unzip-file",
    Shape_File_Step_2: "/api/admin/Utils/step-2-import-shape-file-to-postgis",
    Shape_File_Step_3: "/api/admin/Utils/step-3-created-sld-and-import-to-postgis",
    Shape_File_Step_4: "/api/admin/Utils/step-4-publish",
    // Shape_File_Publish_Finish: "/api/admin/Utils/publish-again",

    //utils-tiff-file
    Tiff_File_Step_1: "/api/admin/Utils/step-1-import-workspace",
    Tiff_File_Step_2: "/api/admin/Utils/step-2-import-tif",

    //News
    News_Create: "/api/admin/News/Create",
    News_Update: "/api/admin/News/Update",
    News_Delete: "/api/admin/News/Delete",
    News_Restore: "/api/admin/News/Restore/{id}",
    News_EmptyTrash: "/api/admin/News/EmptyTrash",
    News_ChangeActiveStatus: "/api/admin/News/ChangeActiveStatus",
    News_ChangeHotStatus: "/api/admin/News/ChangeHotStatus",
    News_ChangeFeatureStatus: "/api/admin/News/ChangeFeatureStatus",
    News_GetListAll: "/api/admin/News/GetListAll",
    News_GetListByCat: "/api/admin/News/GetListByCat",
    News_GetListHot: "/api/admin/News/GetListHot",
    News_GetListByStatus: "/api/admin/News/GetListByStatus",
    News_GetDetail: "/api/admin/News/GetDetail/{id}",

    //Introduce
    Introduce_Create: "/api/admin/Introduce/Create",
    Introduce_Update: "/api/admin/Introduce/Update",
    Introduce_GetListAll: "/api/admin/Introduce/GetListAll",
    Introduce_GetDetail: "/api/admin/Introduce/GetDetail/{id}",

    //Layer Category Parent
    LayerCategoryParent_GetListAll: "/api/admin/LayerCategoryParent/GetListAll",
    LayerCategoryParent_Create: "/api/admin/LayerCategoryParent/Create",
    LayerCategoryParent_Update: "/api/admin/LayerCategoryParent/Update",
    LayerCategoryParent_GetDetail: "/api/admin/LayerCategoryParent/GetDetail/{id}",
    LayerCategoryParent_Delete: "/api/admin/LayerCategoryParent/Delete/{id}",
    LayerCategoryParent_Restore: "/api/admin/LayerCategoryParent/Restore/{id}",
    LayerCategoryParent_EmptyTrash: "/api/admin/LayerCategoryParent/EmptyTrash",
};
