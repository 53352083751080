/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "date-fns";
import moment from "moment";
import * as viVN from "../../../../language/vi-VN.json";
import { NotificationMessageType } from "../../../../utils/configuration";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import "./add-consult-the-community.scss";

//--- Material Control
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

//--- Action
import * as consultTheCommunityAction from "../../../../redux/store/consult-the-community/consult-the-community.store";
import * as opinionFormAction from "../../../../redux/store/opinion-form/opinion-form.store";
import * as appActions from "../../../../core/app.store";

//--- Material Icon
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { DropzoneArea } from "material-ui-dropzone";
import * as configCommon from "../../../../common/config";
import { AttachFile, Description, PictureAsPdf } from "@material-ui/icons";
import { Link } from "react-router-dom";

import FormTemplateComponent from "../form-template/form-template.view";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  accordionSummary: {
    minHeight: "50px !important",
    borderBottom: "1px solid #e3e6f0",
    height: 50,
    paddingLeft: 0,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 2,
  },
}));

const ShowNotificationError = (messages) => {
  ShowNotification(messages, NotificationMessageType.Error);
};

function AddConsultTheCommunity(props) {
  const {
    showLoading,
    isOpen,
    onClose,
    onSuccess,
    planningId,
    planningName,
  } = props;
  const classes = useStyles();

  const today = new Date();
  const fromDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );
  const toDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate() + 1,
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );

  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [content, setContent] = useState();
  const [statusId, setStatusId] = useState(null);
  const [statusLookUpModel, setStatusLookUpModel] = useState(null);
  const [hotNew, setHotNew] = useState(false);
  const [file, setFiles] = useState("");

  const [isPreviewForm, setIsPreviewForm] = useState(false);
  const [previewForm, setPreviewForm] = useState("");
  const [isShowFormTemplate, setShowFormTemplate] = useState(false);
  const [isCreateForm, setCreateForm] = useState(false);

  const [formTemplateLookUpModel, setFormTemplateLookUpModel] = useState([]);
  const [formTemplateModel, setFormTemplateModel] = useState(null);
  const [formTemplateStringModel, setFormTemplateStringModel] = useState("");
  const [errorFormTemplate, setErrorFormTemplate] = useState("");

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([onGetConsultCommunityStatusLookup(), GetLookupFormTemplate()])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetConsultCommunityStatusLookup = () => {
    return new Promise((resolve, reject) => {
      consultTheCommunityAction.GetConsultCommunityStatusLookup().then(
        (res) => {
          res && res.content && setStatusLookUpModel(res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const GetLookupFormTemplate = () => {
    return new Promise((resolve, reject) => {
      opinionFormAction.GetLookupFormTemplate().then(
        (res) => {
          setFormTemplateLookUpModel(
            (res &&
              res.content &&
              res.content.map((item) => {
                return {
                  value: item.id,
                  title: item.title,
                };
              })) ||
            []
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onSetStartDate = (time) => {
    setStartDate(time);
    if (time.getTime() < endDate.getTime()) {
      clearErrors(["startDate", "endDate"]);
    } else {
      setError("startDate", { type: "required" });
    }
  };

  const onSetEndDate = (time) => {
    setEndDate(time);
    if (time.getTime() > startDate.getTime()) {
      clearErrors(["startDate", "endDate"]);
    } else {
      setError("endDate", { type: "required" });
    }
  };

  const handleChangeHotNew = (event) => {
    setHotNew(event.target.checked);
  };

  const handleChangeContent = (content) => {
    clearErrors(["content"]);
    if (content === "<p><br></p>") {
      setError("content", { type: "required" });
      setContent("");
    } else {
      clearErrors("content");
      setContent(content);
    }
  };

  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > configCommon.Configs.fileDocument) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 100MB!");
    } else if (
      rejectFiles.type === ".doc" ||
      rejectFiles.type === ".docx" ||
      rejectFiles.type === ".txt" ||
      rejectFiles.type === ".pdf"
    ) {
      ShowNotificationError(
        "File nhập vào không đúng định dạng doc, docx, txt, pdf"
      );
    } else {
      ShowNotificationError("File nhập vào phải là file tài liệu");
    }
  };

  const handleChange = (files) => {
    if (files) {
      setFiles(files[0]);
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />;
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const handleChangeOpinionForm = (newValue) => {
    if (newValue) {
      setIsPreviewForm(true);
      setPreviewForm(newValue);
    }
  };

  const handleClickOpenFormTemplate = (_isCreateForm = false) => {
    setCreateForm(_isCreateForm);
    setShowFormTemplate(true);
  };

  const handleClickCloseFormTemplate = () => {
    setShowFormTemplate(false);
  };

  const onSaveData = (data, formTemplate) => {
    showLoading(true);
    let body = {
      title: data.commentTitle,
      startTime:
        (startDate && moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
      endTime:
        (endDate && moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
      isHotNew: hotNew,
      statusId: statusId,
      order: Number(data.order),
      content: data.content,
      formOpinion: null,
      planningId: planningId,
      file: file,
      formTemplateStringModel: formTemplate,
    };
    consultTheCommunityAction
      .CreateConsultCommunity(body)
      .then((res) => {
        showLoading(false);
        onSuccess();
      })
      .catch((err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      });
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    if (!formTemplateStringModel && !previewForm) {
      return;
    } else if (!formTemplateStringModel && previewForm) {
      showLoading(true);
      opinionFormAction.GetDetailFormTemplate(previewForm.value).then(
        (res) => {
          showLoading(false);
          if (res && res.content) {
            onSaveData(data, JSON.stringify(res.content));
          } else {
            ShowNotification(
              viVN.Errors[(res && res.errorType) || "UnableHandleException"],
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          showLoading(false);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    } else {
      onSaveData(data, formTemplateStringModel);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography>
          <Typography variant="h6">Thêm ý kiến</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2" dividers>
            <div className="form-group">
              <label className="text-dark">Tên đồ án quy hoạch</label>
              <TextField
                fullWidth
                type="text"
                name="planningName"
                defaultValue={planningName}
                disabled
                variant="outlined"
                size="small"
                placeholder="Tên đồ án quy hoạch"
              />
            </div>

            <div className="form-group">
              <label className="text-dark">
                Tiêu đề<span className="required"></span>
              </label>

              <TextField
                fullWidth
                type="text"
                name="commentTitle"
                variant="outlined"
                size="small"
                placeholder="Tiêu đề"
                error={
                  errors.commentTitle && errors.commentTitle.type === "required"
                }
                inputRef={register({ required: true })}
              />
              {errors.commentTitle &&
                errors.commentTitle.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-4">
                  <label className="text-dark">Chọn form xin ý kiến</label>
                  <div className="mb-1">
                    <Autocomplete
                      options={formTemplateLookUpModel}
                      getOptionLabel={(option) => option.title}
                      disableClearable={true}
                      onChange={(event, newValue) => {
                        handleChangeOpinionForm(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          placeholder="Chọn form xin ý kiến"
                        />
                      )}
                    />
                  </div>
                  {isPreviewForm && (
                    <Link
                      to=""
                      variant="body2"
                      onClick={() => handleClickOpenFormTemplate(false)}
                      className="mr-2"
                    >
                      <div className="d-flex align-items-center">
                        <VisibilityIcon fontSize="small" className="mr-1" />Xem form
                      </div>
                    </Link>
                  )}

                  {
                    !isPreviewForm && (
                      <Link
                        to=""
                        variant="body2"
                        onClick={() => handleClickOpenFormTemplate(true)}
                      >
                        {
                          formTemplateStringModel
                            ? (
                              <div className="d-flex align-items-center">
                                <EditIcon fontSize="small" className="mr-1" />Sửa form
                              </div>
                            )
                            : (
                              <div className="d-flex align-items-center">
                                <AddCircleIcon fontSize="small" className="mr-1" />Tạo form
                              </div>
                            )
                        }
                      </Link>
                    )
                  }
                  <TextField
                    name="formTemplate"
                    type="hidden"
                    inputRef={register({ required: true })}
                    value={
                      (previewForm && previewForm.value) ||
                      formTemplateStringModel
                    }
                  />
                  {errors.formTemplate &&
                    errors.formTemplate.type === "required" && (
                      <div>
                        <span className="error">
                          Vui lòng chọn form mẫu hoặc tạo form
                        </span>
                      </div>
                    )}
                </div>

                <div className="col-4">
                  <label className="text-dark">Ngày bắt đầu</label>
                  <div className="w-100">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="startDate"
                        name="startDate"
                        onChange={(date) => date && onSetStartDate(date)}
                        format="dd/MM/yyyy"
                        value={startDate}
                        fullWidth
                        showTodayButton={true}
                        error={
                          errors.startDate &&
                          errors.startDate.type === "required"
                        }
                      />
                    </MuiPickersUtilsProvider>
                    {errors.startDate &&
                      errors.startDate.type === "required" && (
                        <span className="error">
                          Phải nhỏ hơn ngày kết thúc
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-4">
                  <label className="text-dark">Ngày kết thúc</label>
                  <div className="w-100">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="endDate"
                        name="endDate"
                        onChange={(date) => date && onSetEndDate(date)}
                        format="dd/MM/yyyy"
                        value={endDate}
                        fullWidth
                        showTodayButton={true}
                        error={
                          errors.endDate && errors.endDate.type === "required"
                        }
                      />
                      {errors.endDate && errors.endDate.type === "required" && (
                        <span className="error">Phải lớn hơn ngày bắt đầu</span>
                      )}
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                {statusLookUpModel && statusLookUpModel.length > 0 && (
                  <div className="col-12 col-md-5 col-lg-5">
                    <label className="text-dark">
                      Trạng thái<span className="required"></span>
                    </label>
                    <Autocomplete
                      id="cbStatus"
                      options={statusLookUpModel}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      value={statusLookUpModel[statusId - 1]}
                      onChange={(event, newValue) => {
                        clearErrors("tfStatus");
                        setStatusId(newValue.id);
                        setValue("tfStatus", newValue.id);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          placeholder="Trạng thái"
                        />
                      )}
                    />
                    <TextField
                      name="tfStatus"
                      type="text"
                      fullWidth
                      inputRef={register({ required: true })}
                      hidden
                    />
                    {errors.tfStatus && errors.tfStatus.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                )}

                <div className="col-12 col-md-5 col-lg-5">
                  <label className="text-dark">
                    Thứ tự<span className="required"></span>
                  </label>
                  <TextField
                    name="order"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Thứ tự"
                    inputRef={register({ required: true })}
                    onChange={(e) =>
                      setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                    }
                    error={errors.order && errors.order.type === "required"}
                  />
                  {errors.order && errors.order.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>

                <div className="col-12 col-md-2 col-lg-2">
                  <label className="text-dark">Tin nổi bật</label>
                  <div className="w-100">
                    <Checkbox
                      checked={hotNew}
                      onChange={handleChangeHotNew}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="p-0 mt-2 ml-4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-dark">Nhập file</label>
              <DropzoneArea
                className="dropzoneClass"
                acceptedFiles={[".doc", ".docx", ".txt", ".pdf"]}
                showAlerts={false}
                filesLimit={1}
                maxFileSize={configCommon.Configs.fileDocument}
                getDropRejectMessage={(rejectedFile) =>
                  customMessages(rejectedFile)
                }
                getFileLimitExceedMessage={(filesLimit) =>
                  ShowNotification(
                    `Nhiều nhất ${filesLimit} file`,
                    NotificationMessageType.Error
                  )
                }
                dropzoneText={"Chọn file"}
                onChange={handleChange}
                getPreviewIcon={handlePreviewIcon}
                showFileNames={true}
              />
            </div>

            <div className="form-group">
              <label className="text-dark">Nội dung</label>
              <SunEditor
                enableToolbar={true}
                showToolbar={true}
                setOptions={{
                  height: "auto",
                  minHeight: 200,
                  buttonList: [
                    [
                      "undo",
                      "redo",
                      "font",
                      "fontSize",
                      "formatBlock",
                      "paragraphStyle",
                      "blockquote",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "fontColor",
                      "hiliteColor",
                      "textStyle",
                      "removeFormat",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "lineHeight",
                      "table",
                      "link",
                      "image",
                      "video",
                      "audio",
                      "fullScreen",
                      "showBlocks",
                      "codeView",
                    ],
                  ],
                }}
                onChange={handleChangeContent}
              />
              <TextField
                type="text"
                inputRef={register({ required: true })}
                name="content"
                className="w-100"
                value={content}
                hidden
              />
              {errors.content && errors.content.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShowFormTemplate && (
        <FormTemplateComponent
          isShow={isShowFormTemplate}
          setShow={handleClickCloseFormTemplate}
          formTemplateId={previewForm && previewForm.value}
          formTemplateModel={formTemplateModel}
          setFormTemplateModel={setFormTemplateModel}
          setFormTemplateStringModel={setFormTemplateStringModel}
          isShowActionAddEdit={true}
          isCreateForm={isCreateForm}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddConsultTheCommunity);
