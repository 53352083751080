import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";

//--- Material Icon
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from "@material-ui/icons/Email";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import PostAddIcon from "@material-ui/icons/PostAdd";
import MapIcon from "@material-ui/icons/Map";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import HistoryIcon from "@material-ui/icons/History";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import LayersIcon from '@material-ui/icons/Layers';

//--- Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

//--- Style
import "./sidebar.scss";

export default function Sidebar() {
    const [isToggleSidebar, setIsToggleSidebar] = useState(false);
    const currentLocation = useLocation();
    const onToggleSidebar = () => {
        setIsToggleSidebar(!isToggleSidebar);
    };
    const currentIsHomePage = currentLocation.pathname === "/";
    const currentIsPlanningAnnouncementProcessPage =
        currentLocation.pathname === UrlCollection.PlanningAnnouncementProcess;
    const currentIsSliderPage = currentLocation.pathname === UrlCollection.Slider;
    const currentIsMapDataPage =
        currentLocation.pathname === UrlCollection.MapData;
    const currentIsNewsPage = currentLocation.pathname === UrlCollection.News;
    const currentIsDocumentManagementPage = currentLocation.pathname === UrlCollection.DocumentManagement;
    const currentIsAdministratorPages =
        !currentIsHomePage &&
        !currentIsPlanningAnnouncementProcessPage &&
        !currentIsSliderPage &&
        !currentIsMapDataPage &&
        !currentIsNewsPage &&
        !currentIsDocumentManagementPage;

    return (
        <ul
            className={
                "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" +
                (isToggleSidebar ? " toggled" : "")
            }
            id="accordionSidebar"
        >
            <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/"
            >
                <div className="sidebar-brand-icon">
                    <img
                        src={require("../../assets/images/logo.png")}
                        alt="Logo"
                        width="50"
                    />
                </div>
                <div className="sidebar-brand-text">CGIS Thái Nguyên Admin</div>
            </a>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item">
                <Link className={`nav-link ${currentIsHomePage && "is-active"}`} to="/">
                    <HomeIcon fontSize="small" className="mr-2" />
                    <span>Trang chủ</span>
                </Link>
            </li>

            <hr className="sidebar-divider mb-0" />

            <li className="nav-item">
                <Link
                    className={`nav-link ${currentIsPlanningAnnouncementProcessPage && "is-active"
                        }`}
                    to={UrlCollection.PlanningAnnouncementProcess}
                >
                    <AssignmentIcon fontSize="small" className="mr-2" />
                    <span>Quy trình Công bố QH</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className={`nav-link ${currentIsSliderPage && "is-active"}`}
                    to={UrlCollection.Slider}
                >
                    <PostAddIcon fontSize="small" className="mr-2" />
                    <span>Slider</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className={`nav-link ${currentIsNewsPage && "is-active"}`}
                    to={UrlCollection.News}
                >
                    <PostAddIcon fontSize="small" className="mr-2" />
                    <span>Tin tức</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className={`nav-link ${currentIsDocumentManagementPage && "is-active"}`}
                    to={UrlCollection.DocumentManagement}
                >
                    <PostAddIcon fontSize="small" className="mr-2" />
                    <span>Hồ sơn, văn bản</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className={`nav-link ${currentIsMapDataPage && "is-active"}`}
                    to={UrlCollection.MapData}
                >
                    <MapIcon fontSize="small" className="mr-2" />
                    <span>Bản đồ</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className={`nav-link collapsed ${currentIsAdministratorPages && "is-active"
                        }`}
                    to=""
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                >
                    <SettingsIcon fontSize="small" className="mr-2" />
                    <span>Administrator</span>

                    <FontAwesomeIcon icon={faChevronRight} className="float-right mt-1" />
                    <FontAwesomeIcon icon={faChevronDown} className="float-right mt-1" />
                </Link>

                <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div className="bg-white py-2 collapse-inner rounded">
                        <Link className="collapse-item" to={UrlCollection.Introduce}>
                            <DescriptionIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Giới thiệu</span>
                        </Link>
                        <Link className="collapse-item" to={UrlCollection.CategoryParent}>
                            <LayersIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Nhóm layer</span>
                        </Link>
                        <Link className="collapse-item" to={UrlCollection.EmailTemplate}>
                            <EmailIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Email Template</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.EmailGenerated}>
                            <AllInboxIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Khởi tạo email</span>
                        </Link>

                        <Link
                            className="collapse-item"
                            to={UrlCollection.ContactManagement}
                        >
                            <ContactPhoneIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Liên hệ</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.RoleManagement}>
                            <PeopleIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Chức vụ</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.UserManagement}>
                            <AccountBoxIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Quản lý người dùng</span>
                        </Link>

                        <Link
                            className="collapse-item"
                            to={UrlCollection.CommuneManagement}
                        >
                            <img
                                className="mr-2 mt-n1"
                                src={require("../../assets/icon/commune.png")}
                                alt="commune"
                            />
                            <span>Quản lý xã/phường</span>
                        </Link>

                        <Link
                            className="collapse-item"
                            to={UrlCollection.DistrictManagement}
                        >
                            <img
                                className="mr-2 mt-n1"
                                src={require("../../assets/icon/district.png")}
                                alt="district"
                            />
                            <span>Quản lý quận/huyện</span>
                        </Link>

                        <Link
                            className="collapse-item"
                            to={UrlCollection.ProvinceManagement}
                        >
                            <img
                                className="mr-2 mt-n1"
                                src={require("../../assets/icon/province.png")}
                                alt="province"
                            />
                            <span>Quản lý tỉnh/thành phố</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.LinkGroup}>
                            <img
                                className="mr-2 mt-n1"
                                src={require("../../assets/icon/group-links.png")}
                                alt="group-links"
                            />
                            <span>Danh sách nhóm</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.ServiceLink}>
                            <img
                                className="mr-2 mt-n1"
                                src={require("../../assets/icon/link.png")}
                                alt="link"
                            />
                            <span>Danh sách liên kết</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.UserLogHistory}>
                            <HistoryIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Nhật ký người dùng</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.LandType}>
                            <ViewComfyIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Quản lý loại đất</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.LandTypeDetail}>
                            <ViewAgendaIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Quản lý chi tiết loại đất</span>
                        </Link>

                        <Link className="collapse-item" to={UrlCollection.OpinionForm}>
                            <CallToActionIcon fontSize="small" className="mr-2 mt-n1" />
                            <span>Tạo form ý kiến</span>
                        </Link>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button
                    className="rounded-circle border-0"
                    id="sidebarToggle"
                    onClick={onToggleSidebar}
                >
                    <ChevronLeftIcon
                        fontSize="small"
                        className={"text-white" + (isToggleSidebar ? " d-none" : "")}
                    />
                    <ChevronRightIcon
                        fontSize="small"
                        className={"text-white" + (!isToggleSidebar ? " d-none" : "")}
                    />
                </button>
            </div>
        </ul>
    );
}
