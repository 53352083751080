import React, { useEffect, useState } from "react";

//--- Material Control
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  TextareaAutosize
} from "@material-ui/core";

//--- SunEditor
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

//--- Component
import * as contactAction from '../../../redux/store/contact/contact.store'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ViewContact(props) {
  const { openAddDialog, onHideModal, contactId } = props;
  const [contactModel, setContactModel] = useState();

  const classes = useStyles();

  useEffect(() => {
    contactAction.GetContactDetailByCode(contactId).then(res => res && res.content && setContactModel(res.content)).catch(rejects => console.log(rejects))
  }, [])

  return (
    <div>
      <Dialog
        open={openAddDialog}
        onClose={onHideModal}
        aria-labelledby="alert-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">
            {"Xem thông tin liên hệ"}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onHideModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {contactModel ? <form>
          <DialogContent className="pt-4 pb-2" dividers>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Người gửi</label>
                  <TextField
                    type="text"
                    name="title"
                    className="w-100" defaultValue={contactModel.userName}
                    disabled
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Email người gửi</label>
                  <TextField
                    type="text"
                    name="code"
                    className="w-100"
                    defaultValue={contactModel.sendFrom}
                    color={"danger"}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Số điện thoại</label>
                  <TextField
                    type="text"
                    name="code"
                    className="w-100"
                    defaultValue={contactModel.phoneNumber}
                    color={"danger"}
                    disabled
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-6">  
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Tin nhắn</label>
                <TextareaAutosize className="w-100" disabled={true} value={contactModel.message} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  {contactModel && contactModel.description && <div className="form-group">
                    <label className="text-dark">Mô tả</label>
                    <SunEditor enableToolbar={true} disable={true} showToolbar={true} setContents={contactModel.description} setOptions={{
                      height: 500,
                      buttonList: [
                        ['undo', 'redo',
                          'font', 'fontSize', 'formatBlock',
                          'paragraphStyle', 'blockquote',
                          'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                          'fontColor', 'hiliteColor', 'textStyle',
                          'removeFormat',
                          'outdent', 'indent',
                          'align', 'horizontalRule', 'list', 'lineHeight',
                          'table', 'link', 'image', 'video', 'audio',
                          'fullScreen', 'showBlocks', 'codeView']
                      ]
                    }}
                    />
                  </div>}
                </div>
              </div>
            </div>
          </DialogContent>
        </form> : "Xảy ra lỗi trong hệ thống"}

      </Dialog>
    </div >
  )
}
export default ViewContact