/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as appActions from "../../../../core/app.store";
import * as planningAction from "../../../../redux/store/planning/planning.store";
//--- DropzoneArea
import { DropzoneArea } from "material-ui-dropzone";
import * as config from "../../../../utils/configuration";
import * as configCommon from "../../../../common/config";

const ShowNotificationError = (messages) => {
  ShowNotification(messages, NotificationMessageType.Error);
};

function EditRecordsManagement(props) {
  const classes = useStyles();

  const { isOpen, planningId, onClose, onSuccess, showLoading } = props;

  const [planningModel, setPlanningModel] = useState(null);
  const [planningLookUpModel, setPlanningLookUpModel] = useState(null);
  const [planningStatusModel, setPlanningStatusModel] = useState([]);
  const [planningLevelModel, setPlanningLevelModel] = useState([]);
  const [planningTypeModel, setPlanningTypeModel] = useState([]);
  const [documentTypeModel, setDocumentTypeModel] = useState([]);
  const [approvalAgencyModel, setApprovalAgencyModel] = useState([]);
  const [lookupDistrictModel, setLookupDistrictModel] = useState([]);

  const [planningStatus, setPlanningStatus] = useState("");
  const [planningLevel, setPlanningLevel] = useState("");
  const [planningType, setPlanningType] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [approvalAgency, setApprovalAgency] = useState("");
  const [lookupDistrict, setLookupDistrict] = useState(null);
  const [files, setFiles] = useState("");

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetDetailPlanning(),
      onGetLookUpPlanning(),
      onGetLookUpPlanningStatus(),
      onGetLookUpPlanningLevel(),
      onGetLookUpPlanningType(),
      onGetLookUpDocumentType(),
      getApprovalAgencyLevel(),
      getLookupDistrict(),
    ])
      .then((res) => {
        const [
          planning,
          planningLookUp,
          status,
          level,
          type,
          documentType,
          approvalAgency,
          lookupDistrict,
        ] = res;
        planning && planning.content && setPlanningModel(planning.content);
        planningLookUp &&
          planningLookUp.content &&
          setPlanningLookUpModel(planningLookUp.content);
        status && status.content && setPlanningStatusModel(status.content);
        level && level.content && setPlanningLevelModel(level.content);
        type && type.content && setPlanningTypeModel(type.content);
        documentType &&
          documentType.content &&
          setDocumentTypeModel(documentType.content);
        approvalAgency &&
          approvalAgency.content &&
          setApprovalAgencyModel(approvalAgency.content);
        lookupDistrict &&
          lookupDistrict.content &&
          setLookupDistrictModel(lookupDistrict.content);
        setLookupDistrict(planning.content.districtIds);
        setDistrict(lookupDistrict.content, planning.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const setDistrict = (lookupDistrict, planning) => {
    let district = lookupDistrict.filter((item) => {
      if (
        planning &&
        planning.districtNames &&
        planning.districtNames.length > 0 &&
        planning.districtNames.some((name) => name === item.name)
      ) {
        return item;
      }
    });
    lookupDistrict && lookupDistrict.length > 0 && setLookupDistrict(district);
    if (district.length > 0) {
      setValue("lookupDistrictId", "lookupDistrictId");
      clearErrors("lookupDistrictId");
    } else {
      setValue("lookupDistrictId", "");
      setError("lookupDistrictId", { type: "required" });
    }
  };

  const onGetDetailPlanning = (id = planningId) => {
    return new Promise((resolve, reject) => {
      planningAction.GetDetailPlaning(id).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanning = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanning().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanningStatus = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningStatus().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanningLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningLevel().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanningType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const getApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.ApprovalAgencyLevel().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  const getLookupDistrict = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookupDistrict().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpDocumentType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpDocumentType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    showLoading(true);
    planningAction
      .UpdatePlanning({
        Id: planningModel.id,
        Name: data.planningName,
        PlanningCode: planningModel.planningCode,
        Photo: planningModel.photo,
        PlanningTypeId:
          (planningType && planningType.id) || planningModel.planningTypeId,
        Place: data.place,
        DistrictId: planningModel.districtId,
        Order: parseInt(data.order),
        PlanningAgency: data.planningAgency,
        ConsultingUnit: data.consultingUnit,
        Investor: data.investor,
        PlanningStatusId:
          (planningStatus && planningStatus.id) ||
          planningModel.planningStatusId,
        AgencySubmitted: data.agencySubmitted,
        PlanningUnit: data.planningUnit,
        PlanningLevelId:
          (planningLevel && planningLevel.id) || planningModel.planningLevelId,
        Population: parseInt(data.population),
        Acreage: data.acreage,
        LandForConstruction: data.landForConstruction,
        Report: data.report,
        Note: data.note,
        DocumentTypeId:
          (documentType && documentType.id) || planningModel.documentTypeId,
        MapId: null,
        ApprovalAgencyLevelId: approvalAgency.id || 1,
        PlanningDistrictIds: lookupDistrict.map((item) => {
          return item.id;
        }),
        file: files,
      })
      .then(
        (res) => {
          onSuccess();
        },
        (err) => {
          showLoading(false);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };
  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > configCommon.Configs.DefaultCapacityFile) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 5MB!");
    } else if (
      rejectFiles.type === "image/*" &&
      (rejectFiles.type === "image/jpeg" ||
        rejectFiles.type === "image/png" ||
        rejectFiles.type === "image/jpg" ||
        rejectFiles.type === "image/gif")
    ) {
      ShowNotificationError("Ảnh nhập vào không đúng định dạng");
    } else {
      ShowNotificationError("File nhập vào phải là ảnh");
    }
  };

  const handleChange = (files) => {
    if (files) {
      setFiles(files[0]);
    }
  };

  const initialFilesUrl =
    planningModel && planningModel.photo && planningModel.photo !== "null"
      ? [config.APIUrlDefault + planningModel.photo]
      : [];

  const initialFilesBase64 =
    planningModel &&
    planningModel.photoBase64 &&
    planningModel.photoBase64 !== "null"
      ? ["data:image/jpeg;base64," + planningModel.photoBase64]
      : initialFilesUrl;

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className="border-bottom"
        >
          <Typography variant="h6">Chỉnh sửa dự án quy hoạch</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          {planningModel && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Mã quy hoạch<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    type="text"
                    name="planningCode"
                    defaultValue={planningModel.planningCode}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Tên đồ án QH<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="planningName"
                    defaultValue={planningModel.name}
                    error={
                      errors.planningName &&
                      errors.planningName.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.planningName &&
                    errors.planningName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Loại quy hoạch<span className="required"></span>
                  </label>

                  {planningTypeModel && planningTypeModel.length > 0 && (
                    <Autocomplete
                      options={planningTypeModel}
                      getOptionLabel={(option) => option.name}
                      value={
                        planningType ||
                        planningTypeModel.some(
                          (item) => item.id === planningModel.planningTypeId
                        )
                          ? planningTypeModel.find(
                              (item) => item.id === planningModel.planningTypeId
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setPlanningType(newValue);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="planningTypeId"
                          inputRef={register({ required: true })}
                          error={
                            errors.planningTypeId &&
                            errors.planningTypeId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {errors.planningTypeId &&
                    errors.planningTypeId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Địa điểm<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="place"
                    defaultValue={planningModel.place}
                    error={errors.place && errors.place.type === "required"}
                    variant="outlined"
                    size="small"
                  />
                  {errors.place && errors.place.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Thứ tự<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="order"
                    onChange={(e) =>
                      setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                    }
                    defaultValue={planningModel.order}
                    error={errors.order && errors.order.type === "required"}
                    variant="outlined"
                    size="small"
                  />
                  {errors.order && errors.order.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Cấp quy hoạch<span className="required"></span>
                  </label>

                  {planningLevelModel && planningLevelModel.length > 0 && (
                    <Autocomplete
                      id="planningLevelId"
                      options={planningLevelModel}
                      getOptionLabel={(option) => option.name}
                      value={
                        planningLevel ||
                        planningLevelModel.some(
                          (item) => item.id === planningModel.planningLevelId
                        )
                          ? planningLevelModel.find(
                              (item) =>
                                item.id === planningModel.planningLevelId
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setPlanningLevel(newValue);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="planningLevelId"
                          inputRef={register({ required: true })}
                          error={
                            errors.planningLevelId &&
                            errors.planningLevelId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {errors.planningLevelId &&
                    errors.planningLevelId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Cơ quan phê duyệt
                    <span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="planningAgency"
                    defaultValue={planningModel.planningAgency}
                    error={
                      errors.planningAgency &&
                      errors.planningAgency.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.planningAgency &&
                    errors.planningAgency.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Đơn vị lập quy hoạch<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="planningUnit"
                    defaultValue={planningModel.planningUnit}
                    error={
                      errors.planningUnit &&
                      errors.planningUnit.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.planningUnit &&
                    errors.planningUnit.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Cơ quan trình
                    <span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="agencySubmitted"
                    defaultValue={planningModel.agencySubmitted}
                    error={
                      errors.agencySubmitted &&
                      errors.agencySubmitted.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.agencySubmitted &&
                    errors.agencySubmitted.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Đơn vị tư vấn<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="consultingUnit"
                    defaultValue={planningModel.consultingUnit}
                    error={
                      errors.consultingUnit &&
                      errors.consultingUnit.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.consultingUnit &&
                    errors.consultingUnit.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Chủ đầu tư<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="investor"
                    defaultValue={planningModel.investor}
                    error={
                      errors.investor && errors.investor.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.investor && errors.investor.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="text-dark">Quyết định phê duyệt</label>
                  <TextField
                    fullWidth
                    type="text"
                    name="report"
                    defaultValue={planningModel.report}
                    variant="outlined"
                    size="small"
                    inputRef={register}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Loại hồ sơ<span className="required"></span>
                  </label>

                  {documentTypeModel && documentTypeModel.length > 0 && (
                    <Autocomplete
                      id="documentTypeId"
                      options={documentTypeModel}
                      getOptionLabel={(option) => option.name}
                      value={
                        documentType ||
                        documentTypeModel.some(
                          (item) => item.id === planningModel.documentTypeId
                        )
                          ? documentTypeModel.find(
                              (item) => item.id === planningModel.documentTypeId
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setDocumentType(newValue);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="documentTypeId"
                          inputRef={register({ required: true })}
                          error={
                            errors.documentTypeId &&
                            errors.documentTypeId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {errors.documentTypeId &&
                    errors.documentTypeId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Dân số (người)<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="population"
                    defaultValue={planningModel.population}
                    onChange={(e) =>
                      setValue(
                        "population",
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                    error={
                      errors.population && errors.population.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.population &&
                    errors.population.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Diện tích (ha)<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      required: true,
                      pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                    })}
                    type="text"
                    name="acreage"
                    defaultValue={planningModel.acreage}
                    error={
                      errors.acreage &&
                      (errors.acreage.type === "required" ||
                        errors.acreage.type === "pattern")
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.acreage && errors.acreage.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                  {errors.acreage && errors.acreage.type === "pattern" && (
                    <span className="error">
                      Chỉ có thể nhập số hoặc số thập phân
                    </span>
                  )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Đất xây dựng (ha)<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      required: true,
                      pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                    })}
                    type="text"
                    name="landForConstruction"
                    defaultValue={planningModel.landForConstruction}
                    error={
                      errors.landForConstruction &&
                      (errors.landForConstruction.type === "required" ||
                        errors.landForConstruction.type === "pattern")
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.landForConstruction &&
                    errors.landForConstruction.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.landForConstruction &&
                    errors.landForConstruction.type === "pattern" && (
                      <span className="error">
                        Chỉ có thể nhập số hoặc số thập phân
                      </span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Quận/huyện<span className="required"></span>
                  </label>
                  {lookupDistrictModel && lookupDistrictModel.length > 0 && (
                    <Autocomplete
                      multiple
                      options={lookupDistrictModel}
                      getOptionLabel={(option) => option.name}
                      defaultValue={lookupDistrictModel.filter((item) => {
                        if (
                          planningModel &&
                          planningModel.districtNames &&
                          planningModel.districtNames.length > 0 &&
                          planningModel.districtNames.some(
                            (name) => name === item.name
                          )
                        ) {
                          return item;
                        }
                      })}
                      onChange={(event, newValue) => {
                        setLookupDistrict(newValue);
                        if (newValue.length > 0) {
                          setValue("lookupDistrictId", "11");
                          clearErrors("lookupDistrictId");
                        } else {
                          setValue("lookupDistrictId", "");
                          setError("lookupDistrictId", { type: "required" });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            errors.lookupDistrictId &&
                            errors.lookupDistrictId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  <TextField
                    name="lookupDistrictId"
                    hidden
                    inputRef={register({ required: true })}
                  />
                  {errors.lookupDistrictId &&
                    errors.lookupDistrictId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Cấp phê duyệt<span className="required"></span>
                  </label>
                  {approvalAgencyModel && approvalAgencyModel.length > 0 && (
                    <Autocomplete
                      options={approvalAgencyModel}
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        approvalAgency ||
                        approvalAgencyModel.some(
                          (item) =>
                            item.id === planningModel.approvalAgencyLevelId || 1
                        )
                          ? approvalAgencyModel.find(
                              (item) =>
                                item.id ===
                                  planningModel.approvalAgencyLevelId || 1
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setApprovalAgency(newValue);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="approvalAgencyId"
                          inputRef={register({ required: true })}
                          error={
                            errors.approvalAgencyId &&
                            errors.approvalAgencyId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {errors.approvalAgencyId &&
                    errors.approvalAgencyId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Trạng thái<span className="required"></span>
                  </label>

                  {planningStatusModel && planningStatusModel.length > 0 && (
                    <Autocomplete
                      id="planningStatusId"
                      options={planningStatusModel}
                      getOptionLabel={(option) => option.name}
                      value={
                        planningStatus ||
                        planningStatusModel.some(
                          (item) => item.id === planningModel.planningStatusId
                        )
                          ? planningStatusModel.find(
                              (item) =>
                                item.id === planningModel.planningStatusId
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setPlanningStatus(newValue);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="planningStatusId"
                          inputRef={register({ required: true })}
                          error={
                            errors.planningStatusId &&
                            errors.planningStatusId.type === "required"
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                  {errors.planningStatusId &&
                    errors.planningStatusId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>

                <div className="col-6"></div>
              </div>

              <div className="form-group">
                <label className="text-dark">Ghi chú</label>

                <textarea
                  name="note"
                  rows="5"
                  className="form-control"
                  defaultValue={planningModel.note}
                  ref={register}
                ></textarea>
              </div>

              <div className="form-group">
                <label className="text-dark">
                  Ảnh (Ảnh nhập vào nhỏ hơn 5MB)
                </label>
                <DropzoneArea
                  className="dropzoneClass"
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/jpg",
                    "image/gif",
                  ]}
                  showAlerts={false}
                  filesLimit={1}
                  initialFiles={initialFilesBase64}
                  getDropRejectMessage={(rejectedFile) =>
                    customMessages(rejectedFile)
                  }
                  getFileLimitExceedMessage={(filesLimit) =>
                    ShowNotification(
                      `Nhiều nhất ${filesLimit} ảnh`,
                      NotificationMessageType.Error
                    )
                  }
                  dropzoneText={"Chọn ảnh"}
                  onChange={handleChange}
                />
              </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRecordsManagement);
