import React, { useState } from "react";
import { useForm } from "react-hook-form";

//--- Styles
import "../slider.scss";

//--- Material Control
import {
  DialogActions,
  TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import FileInputComponent from "../../../components/file-input/file-input.view";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as homePageAction from "../../../redux/store/home-page/home-page.store";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddSlider(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListHomePage,
    rowsPerPage,
    setOrder,
    setOrderBy,
  } = props;

  const [avatar, setAvatar] = useState("");
  const [status, setStatus] = useState(1);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    let formData = new FormData();
    data.title && formData.append("title", data.title);
    data.content && formData.append("introduce", data.content);
    data.order && formData.append("order", data.order);
    data.link && formData.append("link", data.link);
    avatar && formData.append("file", avatar);
    formData.append("avatar", "");
    formData.append("status", status ? true : false);

    // onSuccess();
    homePageAction
      .CreateHomePage(formData)
      .then((result) => {
        if (result) {
          setOrder("asc");
          setOrderBy("order");
          GetListHomePage(1, rowsPerPage);
          onSuccess();
          ShowNotification(
            viVN.Success.SliderAddSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  function handleChangeSelect(event) {
    setStatus(event.target.value);
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm Slider</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <label className="text-dark">
                Tiêu đề<span className="required"></span>
              </label>
              <TextField
                name="title"
                error={
                  errors.title &&
                  (errors.title.type === "required" ||
                    errors.title.type === "maxLength")
                }
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({ required: true, maxLength: 200 })}
                onChange={(e) =>
                  setValue("title", e.target.value.toUpperCase())
                }
              />
              {errors.title && errors.title.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
              {errors.title && errors.title.type === "maxLength" && (
                <span className="error">Trường này không quá 200 ký tự</span>
              )}
            </div>

            <div className="form-group">
              <label className="text-dark">Tóm tắt</label>
              <TextareaAutosize
                name="content"
                rowsMin={3}
                className={"form-control"}
                ref={register}
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Thứ tự<span className="required"></span>
                  </label>
                  <TextField
                    inputRef={register({ required: true })}
                    type="text"
                    name="order"
                    className="w-100"
                    onChange={(e) =>
                      setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                    }
                    error={errors.order && errors.order.type === "required"}
                  />
                  {errors.order && errors.order.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Trạng thái</label>
                  <br />
                  <Select className="w-100" onChange={handleChangeSelect}>
                    <MenuItem value={1}>Hiện</MenuItem>
                    <MenuItem value={0}>Ẩn</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Đường dẫn</label>
                  <TextField
                    inputRef={register}
                    type="text"
                    name="link"
                    className="w-100"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-dark">
                Ảnh<span className="required"></span> (Ảnh nhập vào nhỏ hơn 5MB)
              </label>
              <FileInputComponent
                onChangeFiles={setAvatar}
                maxFileSize={5}
                filesLimit={1}
                register={register}
                acceptedFiles={["jpeg", "png", "jpg", "gif"]}
              />
              {errors.fileInput && errors.fileInput.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
