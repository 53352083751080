/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./add-announced.scss"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

import * as viVN from "../../../../language/vi-VN.json";
import * as appActions from "../../../../core/app.store";
import * as statementAction from "../../../../redux/store/statement/statement.store";

//--- DropzoneArea
import { DropzoneArea } from 'material-ui-dropzone';
import * as configCommon from "../../../../common/config";
import { AttachFile, Description, PictureAsPdf } from '@material-ui/icons';

const ShowNotificationError = (messages) => {
  ShowNotification(messages, NotificationMessageType.Error)
}

function CreateAnnounced(props) {
  const { showLoading, planningId, onClose, onSuccess, classes } = props;

  const [statementStatusList, setStatementStatusList] = useState([]);
  const [statementStatus, setStatementStatus] = useState({ id: 1 });
  const [content, setContent] = useState();
  const [file, setFiles] = useState("");

  const { register, handleSubmit, errors, setError, clearErrors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, [planningId]);

  const onGetData = () => {
    showLoading(true);
    Promise.all([onGetStatementStatusLookup()])
      .then((res) => {
        const [statementStatusModel] = res;
        setStatementStatusList(statementStatusModel && statementStatusModel.content && statementStatusModel.content.length > 0 ? statementStatusModel.content : []);
        setStatementStatus(statementStatusModel && statementStatusModel.content && statementStatusModel.content.length > 0 && statementStatusModel.contentstatementStatusModel.content[0]);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetStatementStatusLookup = () => {
    return new Promise((resolve, reject) => {
      statementAction.GetStatementStatusLookup().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onChangeContent = (content) => {
    clearErrors(["content"]);
    if (content === "<p><br></p>") {
      setError("content", { type: "required" });
      setContent("");
    } else {
      clearErrors("content");
      setContent(content);
    }
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    showLoading(true);
    statementAction
      .CreateStatement({
        id: 0,
        planningId: planningId,
        title: data.title,
        brief: data.brief,
        content: data.content,
        numberOfDecisions: data.numberOfDecisions,
        issuedDate: new Date(),
        approvalAgency: data.approvalAgency,
        statementStatusId: statementStatus.id,
        yearOfStatement: data.yearOfStatement,
        file: file
      })
      .then(
        (res) => {
          ShowNotification(
            "Công bố thành công",
            NotificationMessageType.Success
          );
          onSuccess();
          showLoading(false);
        },
        (err) => {
          showLoading(false);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };

  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > configCommon.Configs.fileDocument) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 100MB!");
    } else if (rejectFiles.type === ".doc" || rejectFiles.type === ".docx" || rejectFiles.type === ".txt" || rejectFiles.type === ".pdf") {
      ShowNotificationError("File nhập vào không đúng định dạng doc, docx, txt, pdf");
    } else {
      ShowNotificationError("File nhập vào phải là file tài liệu");
    }
  };

  const handleChange = (files) => {
    if (files) {
      setFiles(files[0]);
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file
    const iconProps = {
      className: classes.image,
    }

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />
      default:
        return <AttachFile {...iconProps} />
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3">
        <div className="form-group">
          <label className="text-dark">
            Tiêu đề Công bố QH<span className="required"></span>
          </label>
          <TextField
            fullWidth
            inputRef={register({ required: true })}
            type="text"
            name="title"
            error={errors.title && errors.title.type === "required"}
          />
          {errors.title && errors.title.type === "required" && (
            <span className="error">Trường này là bắt buộc</span>
          )}
        </div>

        <div className="form-group">
          <label className="text-dark">
            Tóm tắt<span className="required"></span>
          </label>
          <textarea
            name="brief"
            rows="5"
            ref={register({ required: true })}
            className={
              "form-control" +
              (errors.brief && errors.brief.type === "required"
                ? " is-invalid"
                : "")
            }
          ></textarea>
          {errors.brief && errors.brief.type === "required" && (
            <span className="error">Trường này là bắt buộc</span>
          )}
        </div>

        <div className="form-group">
          <label className="text-dark">
            Bài viết Công bố QH<span className="required"></span>
          </label>
          <SunEditor
            enableToolbar={true}
            showToolbar={true}
            setOptions={{
              height: 500,
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
            onChange={onChangeContent}
            onBlur={(event, editorContents) => onChangeContent(editorContents)}
          />
          <TextField
            type="text"
            inputRef={register({ required: true })}
            name="content"
            className="d-none"
            value={content}
          />
          {errors.content && errors.content.type === "required" && (
            <span className="error">Trường này là bắt buộc</span>
          )}
        </div>

        <div className="form-group">
          <label className="text-dark">
            Quyết định phê duyệt<span className="required"></span>
          </label>
          <TextField
            fullWidth
            inputRef={register({ required: true })}
            type="text"
            name="numberOfDecisions"
            error={
              errors.numberOfDecisions &&
              errors.numberOfDecisions.type === "required"
            }
          />
          {errors.numberOfDecisions &&
            errors.numberOfDecisions.type === "required" && (
              <span className="error">Trường này là bắt buộc</span>
            )}
        </div>

        <div className="form-group">
          <label className="text-dark">Nhập file</label>
          <DropzoneArea className="dropzoneClass"
            acceptedFiles={['.doc', '.docx', '.txt', '.pdf']}
            showAlerts={false}
            filesLimit={1}
            maxFileSize={configCommon.Configs.fileDocument}
            getDropRejectMessage={(rejectedFile) => customMessages(rejectedFile)}
            getFileLimitExceedMessage={(filesLimit) => ShowNotification(`Nhiều nhất ${filesLimit} file`, NotificationMessageType.Error)}
            dropzoneText={"Chọn file"}
            onChange={handleChange}
            getPreviewIcon={handlePreviewIcon}
            showFileNames={true}
          />
        </div>

        <div className="form-group">
          <label className="text-dark">
            Cơ quan phê duyệt<span className="required"></span>
          </label>
          <TextField
            fullWidth
            inputRef={register({ required: true })}
            type="text"
            name="approvalAgency"
            error={
              errors.approvalAgency && errors.approvalAgency.type === "required"
            }
          />
          {errors.approvalAgency &&
            errors.approvalAgency.type === "required" && (
              <span className="error">Trường này là bắt buộc</span>
            )}
        </div>

        <div className="form-group">
          <label className="text-dark">
            Trạng thái<span className="required"></span>
          </label>
          {statementStatusList && statementStatusList.length > 0 && (
            <Autocomplete
              fullWidth
              options={statementStatusList}
              getOptionLabel={(option) => option.name}
              value={statementStatusList[0]}
              onChange={(event, newValue) => {
                setStatementStatus(newValue);
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="statementStatus"
                  inputRef={register({ required: true })}
                  error={
                    errors.statementStatus &&
                    errors.statementStatus.type === "required"
                  }
                />
              )}
            />
          )}
          {errors.statementStatus &&
            errors.statementStatus.type === "required" && (
              <span className="error">Trường này là bắt buộc</span>
            )}
        </div>

        <div className="form-group">
          <label className="text-dark">
            Năm công bố<span className="required"></span>
          </label>
          <TextField
            fullWidth
            inputRef={register({ required: true, maxLength: 4 })}
            type="text"
            name="yearOfStatement"
            error={
              errors.yearOfStatement && (
                errors.yearOfStatement.type === "required" || errors.yearOfStatement.type === "maxLength"
              )
            }
            onChange={(e => setValue("yearOfStatement", e.target.value.replace(/[^0-9]/g, "")))}
          />
          {errors.yearOfStatement && (
            errors.yearOfStatement.type === "required" && (
              <span className="error">Trường này là bắt buộc</span>
            ) || errors.yearOfStatement.type === "maxLength" && (
              <span className="error">Không được quá 4 ký tự</span>
            ))}
        </div>

      </div>
      <div className="text-right border-top pt-3 pr-2 pb-3">
        <Button
          type="button"
          onClick={onClose}
          variant="contained"
          className={classes.button}
          startIcon={<CloseIcon />}
        >
          Hủy
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Lưu
        </Button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnnounced);
