import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DropzoneArea } from 'material-ui-dropzone';

//--- Action
import * as serviceLinkAction from "../../../redux/store/service-link/service-link.store";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select, MenuItem
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//configuration
import * as config from "../../../utils/configuration";
import * as configCommon from "../../../common/config";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditServiceLinkManagement(props) {
  const classes = useStyles();

  const { isOpen, onClose, onSuccess, serviceLinkId, ShowNotificationError, setOrder, setOrderBy, GetListServiceLink, rowsPerPage, showLoading } = props;

  const [serviceLinkModel, setServiceLinkModel] = useState();
  const [linkGroupLookup, setLinkGroupLookup] = useState();
  const [file, setFiles] = useState();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    showLoading(true);
    Promise.all([GetDetailServiceLink(serviceLinkId), GetLookupLinkGroup()]).then((res) => {
      const [serviceLinkModel, linkGroupLookup] = res;
      setServiceLinkModel(serviceLinkModel && serviceLinkModel.content ? serviceLinkModel.content : []);
      setLinkGroupLookup(linkGroupLookup && linkGroupLookup.content ? linkGroupLookup.content : []);
      showLoading(false)
    }).catch((err) => {
      showLoading(false);
    })
  }, [])
  const GetDetailServiceLink = (serviceLinkId) => {
    return new Promise((resolve, reject) => {
      serviceLinkAction.GetDetailServiceLink(serviceLinkId).then((res) => {
        return resolve(res)
      }, (err) => {
        reject(err);
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      })
    })
  }
  const GetLookupLinkGroup = () => {
    return new Promise((resolve, reject) => {
      serviceLinkAction.GetLookupLinkGroup().then((res) => {
        return resolve(res);
      }, (err) => {
        reject(err);
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      })
    })
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    const formData = new FormData();
    formData.append("id", serviceLinkId);
    formData.append("name", data.name);
    formData.append("file", file);
    formData.append("groupId", data.groupId);
    formData.append("link", data.link);
    formData.append("order", data.order);
    formData.append("active", isActive);

    serviceLinkAction.UpdateServiceLink(formData).then((result) => {
      if (result) {
        setOrder("desc");
        setOrderBy("modifiedDate")
        GetListServiceLink(1, rowsPerPage);
        onSuccess();
        ShowNotification(
          viVN.Success.EditProvice,
          NotificationMessageType.Success
        )
      }
    }).catch((err) => {
      onSuccess();
      ShowNotification(
        viVN.Errors[err.errorType],
        NotificationMessageType.Error
      )
    })
  };
  const customMessages = (rejectFiles) => {
    if (rejectFiles.size > configCommon.Configs.DefaultCapacityFile) {
      ShowNotificationError("Kích thước file phải nhỏ hơn 5MB!");
    } else if (rejectFiles.type === "image/*" && (rejectFiles.type === "image/jpeg" || rejectFiles.type === "image/png" || rejectFiles.type === "image/jpg" || rejectFiles.type === "image/gif")) {
      ShowNotificationError("Ảnh nhập vào không đúng định dạng");
    } else {
      ShowNotificationError("File nhập vào phải là ảnh");
    }
  }

  const handleChange = (files) => {
    if (files) {
      setFiles(files[0]);
    }
  }


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa danh sách liên kết</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {serviceLinkModel && <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Tên danh sách liên kết<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    defaultValue={serviceLinkModel.name}
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Sắp xếp<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="order"
                    className="w-100"
                    defaultValue={serviceLinkModel.order}
                    inputRef={register({
                      required: true,
                    })}
                    onChange={(e => setValue("order", e.target.value.replace(/[^0-9]/g, "")))}
                    error={errors.order && errors.order.type === "required"}
                  />
                  {errors.order && errors.order.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Danh sách nhóm<span className="required"></span></label>
                  <Select fullWidth defaultValue={serviceLinkModel.groupId} onChange={(event) => setValue("groupId", event.target.value)} >
                    {linkGroupLookup && linkGroupLookup.length > 0 && linkGroupLookup.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem >)}
                  </Select>
                  <TextField
                    type="text"
                    name="groupId"
                    className="w-100"
                    defaultValue={serviceLinkModel.groupId}
                    inputRef={register({ required: true })}
                    hidden
                  />
                  {errors.groupId && errors.groupId.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Link<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="link"
                    defaultValue={serviceLinkModel.link}
                    className="w-100"
                    inputRef={register}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="text-dark">Ảnh (Ảnh nhập vào nhỏ hơn 5MB)</label>
              <DropzoneArea
                acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'image/gif']}
                showAlerts={false}
                filesLimit={1}
                initialFiles={[config.APIUrlDefault + serviceLinkModel.image]}
                getDropRejectMessage={(rejectedFile) => customMessages(rejectedFile)}
                getFileLimitExceedMessage={(filesLimit) => ShowNotification(`Nhiều nhất ${filesLimit} ảnh`, NotificationMessageType.Error)}
                dropzoneText={"Chọn ảnh"}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Danh sách nhóm<span className="required"></span></label>
                  <Select fullWidth defaultValue={isActive} onChange={(event) => setIsActive(event.target.value)} >
                    <MenuItem value={true}>{"Hoạt động"}</MenuItem >
                    <MenuItem value={false}>{"Không hoạt động"}</MenuItem >
                  </Select>
                </div>
              </div>
            </div>

          </DialogContent>}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
