/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//--- Action
import * as homePageAction from "../../../redux/store/home-page/home-page.store";

//--- Material Control
import {
  DialogActions,
  TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import FileInputComponent from "../../../components/file-input/file-input.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
} from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as appActions from "../../../core/app.store";

//--- Styles
import "../slider.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EditSlider(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    sliderId,
    setOrder,
    setOrderBy,
    GetListHomePage,
    rowsPerPage,
  } = props;
  
  const [sliderModel, setSliderModel] = useState();
  const [avatar, setAvatar] = useState();
  const [file, setFiles] = useState("");
  const [status, setStatus] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [isHaveAvatar, setIsHaveAvatar] = useState(true);
  const [avatarRequiredWarning, setAvatarRequiredWarning] = useState(false);

  useEffect(() => {
    homePageAction
      .GetDetailHomePage(sliderId)
      .then((res) => {
        if (res && res.content) {
          setSliderModel(res.content);
          setAvatar(res.content.avatar);
          setStatus(res.content.status);
          setIsHaveAvatar(!!res.content.avatarBase64);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    if (!isHaveAvatar) {
      setAvatarRequiredWarning(true);
      return;
    }

    let formData = new FormData();
    formData.append("id", sliderId);
    data.title && formData.append("title", data.title);
    data.content && formData.append("introduce", data.content);
    data.order && formData.append("order", Number(data.order));
    data.link && formData.append("link", data.link);
    formData.append("file", file);
    formData.append("avatar", avatar);
    formData.append("status", status);
    formData.append("isDelete", isDelete);
    homePageAction
      .UpdateHomePage(formData)
      .then((result) => {
        if (result) {
          setOrder("asc");
          setOrderBy("order");
          GetListHomePage(1, rowsPerPage);
          onSuccess();
          ShowNotification(
            viVN.Success.SliderEditSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  function handleChangeSelect(event) {
    setStatus(event.target.value);
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa Slider</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {sliderModel && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group">
                <label className="text-dark">
                  Tiêu đề<span className="required"></span>
                </label>
                <TextField
                  inputRef={register({ required: true })}
                  name="title"
                  error={errors.title && errors.title.type === "required"}
                  fullWidth
                  type="text"
                  className="form-control"
                  defaultValue={sliderModel.title}
                  onChange={(e) =>
                    setValue("title", e.target.value.toUpperCase())
                  }
                />
                {errors.title && errors.title.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
              </div>

              <div className="form-group">
                <label className="text-dark">Tóm tắt</label>
                <TextareaAutosize
                  name="content"
                  rowsMin={3}
                  className={"form-control"}
                  ref={register}
                  defaultValue={sliderModel.introduce}
                />
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">
                      Thứ tự<span className="required"></span>
                    </label>
                    <TextField
                      inputRef={register({ required: true })}
                      type="text"
                      name="order"
                      className="w-100"
                      defaultValue={sliderModel.order}
                      onChange={(e) =>
                        setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                      }
                      error={errors.order && errors.order.type === "required"}
                    />
                    {errors.order && errors.order.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Trạng thái</label>
                    <br />
                    <Select
                      className="w-100"
                      value={status}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={true}>Hiện</MenuItem>
                      <MenuItem value={false}>Ẩn</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Đường dẫn</label>
                    <TextField
                      inputRef={register}
                      type="text"
                      name="link"
                      className="w-100"
                      defaultValue={sliderModel.link}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="text-dark">
                  Ảnh<span className="required"></span> (Ảnh nhập vào nhỏ hơn
                  5MB)
                </label>
                <FileInputComponent
                  onChangeFiles={setFiles}
                  onDeleteFiles={(e) => setIsDelete(e ? true : false)}
                  maxFileSize={5}
                  filesLimit={1}
                  register={register}
                  acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                  initialFiles={[APIUrlDefault + sliderModel.avatar]}
                />
                {errors.fileInput && errors.fileInput.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
              </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditSlider);
